import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlMultiplierComponent, ControlTemplateDirective } from './control-multiplier.component'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FmxErrorsModule } from '../errors/errors.module'

import { OrderModule } from 'ngx-order-pipe'

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, FmxErrorsModule, MatFormFieldModule, OrderModule],
	declarations: [ControlMultiplierComponent, ControlTemplateDirective],
	exports: [ControlMultiplierComponent, ControlTemplateDirective],
})
export class ControlMultiplierModule {}
