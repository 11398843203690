export interface FirestoreFirebaseFunctionsAware {
	/** Called in the firestore.document.onCreate() firebase function */
	onCreate(): void

	/** Called in the firestore.document.onUpdate() firebase function */
	onUpdate(): void
}

export abstract class FirestoreDoc implements FirestoreFirebaseFunctionsAware {
	uid!: string
	createdAt!: string
	updatedAt!: string

	static of<T extends FirestoreDoc>(Doc: new () => T, source: T): T {
		return Object.assign(new Doc(), source)
	}

	onCreate(): void {
		this.createdAt = new Date().toISOString()
		this.updatedAt = new Date().toISOString()
	}

	onUpdate(): void {
		this.updatedAt = new Date().toISOString()
	}

	equals(other: FirestoreDoc): boolean {
		if (!other) {
			return false
		}
		return this.uid === other.uid
	}

	withoutMetadata(): FirestoreDoc {
		const { createdAt, updatedAt, ...doc } = { ...this } as FirestoreDoc
		return doc as FirestoreDoc
	}
}

export function stripFireDocAuditProperties<D extends FirestoreDoc>(doc: D): D {
	const { createdAt, updatedAt, ...result } = doc
	return result as D
}
