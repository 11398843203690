import { Injectable } from '@angular/core'
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'

import { FileUpload } from 'domain/fileupload'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { Utils } from 'src/app/shared/utils/utils'

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(
		private db: AngularFireDatabase,
		private storage: AngularFireStorage,
		private afStore: AngularFirestore,
	) {}

	pushFileToStorage(fileUpload: FileUpload, item: any, table: any, ext: any, folder = ''): Observable<any> {
		let filePath = `${this.basePath}/${item.id}.${ext}`
		if (folder !== '') filePath = `${this.basePath}/${folder}/${item.id}.${ext}`

		const storageRef = this.storage.ref(filePath)
		const uploadTask = this.storage.upload(filePath, fileUpload.file)

		uploadTask
			.snapshotChanges()
			.pipe(
				finalize(async () => {
					await storageRef.getDownloadURL().subscribe((downloadURL) => {
						fileUpload.url = downloadURL
						fileUpload.name = fileUpload.file.name
						return this.saveFileData(fileUpload, item, table)
					})
				}),
			)
			.subscribe()

		return uploadTask.percentageChanges()
	}

	getFileUploads(numberItems: any): AngularFireList<FileUpload> {
		return this.db.list(this.basePath, (ref) => ref.limitToLast(numberItems))
	}

	deleteFileUpload(fileUpload: FileUpload) {
		this.deleteFileStorage(fileUpload.name)
	}

	private saveFileData(fileUpload: FileUpload, item: any, table: any) {
		item.file_name = fileUpload.name
		item.file_url = fileUpload.url

		if (!item.id) item.id = Utils.guid()

		const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${table}/${item.id}`)
		itemRef.set(item, { merge: true })

		return item
	}

	private deleteFileDatabase(key: string) {
		return this.db.list(this.basePath).remove(key)
	}

	private deleteFileStorage(name: string) {
		//TODO: this probably has to be more general purpose
		const storageRef = this.storage.ref(this.basePath + '/snapshots/' + name)
		// console.log(storageRef, this.basePath + '/snapshots/' + name)
		storageRef.delete()
	}

	private basePath = '/uploads'
}
