<div class="table-container">
	<div *ngIf="dataSource.loading$ | async" class="spinner-container">
		<mat-spinner></mat-spinner>
	</div>

	<table
		(matSortChange)="sort.emit($event)"
		[dataSource]="dataSource"
		[multiTemplateDataRows]="expandable"
		class="w-100"
		mat-table
		matSort
		matSortDisableClear
	>
		<ng-container *ngFor="let colDef of columns$ | async; trackBy: trackBy" [matColumnDef]="colDef.property">
			<th
				*matHeaderCellDef
				[disabled]="!colDef.sortable"
				class="font-weight-bolder"
				mat-header-cell
				mat-sort-header
				style="color: black"
			>
				{{ colDef.label | translate }}
			</th>

			<td *matCellDef="let row" [style.width]="colDef.width" class="table-actions" mat-cell>
				<ng-container (action)="columnAction.emit($event)" [definition]="colDef" [row]="row" appTableCellContent>
				</ng-container>
			</td>
		</ng-container>
		<ng-container *ngIf="expandable">
			<ng-container *ngLet="columnNames$ | async as columnNames" matColumnDef="expandedDetail">
				<td *matCellDef="let row" [attr.colspan]="columnNames.length" mat-cell style="padding: 0 !important">
					<div [@detailExpand]="row === expanded ? 'expanded' : 'collapsed'" class="expanded-content">
						<ng-container *ngIf="expandableContentTemplateDirective">
							<ng-container
								*ngTemplateOutlet="expandableContentTemplateDirective.templateRef; context: $any({ $implicit: row })"
							></ng-container>
						</ng-container>
					</div>
				</td>
			</ng-container>
		</ng-container>

		<ng-container *ngLet="columnNames$ | async as columnNames">
			<ng-container *ngIf="!omitHeaders">
				<tr *matHeaderRowDef="columnNames" mat-header-row></tr>
			</ng-container>
			<tr
				(click)="toggleExpansion(row)"
				*matRowDef="let row; columns: columnNames"
				[class.expanded-row]="expanded === row"
				mat-row
			></tr>
			<ng-container *ngIf="expandable">
				<tr *matRowDef="let row; columns: ['expandedDetail']" class="expanded-row" mat-row></tr>
			</ng-container>
		</ng-container>
	</table>
</div>
