import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Observable, Subject, throwError, timer } from 'rxjs'
import { Directive, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { catchError, finalize, first } from 'rxjs/operators'
import { LoadingService } from '../../core/services/loading.service'

@Directive()
export abstract class AbstractFormDialog<M extends object, D extends AbstractFormDialog<M, D>> implements OnInit {
	readonly form: UntypedFormGroup = new UntypedFormGroup({ model: new UntypedFormControl({}) })
	readonly errors$: Subject<string> = new Subject<string>()

	protected constructor(
		protected readonly dialogRef: MatDialogRef<D>,
		readonly loadingService: LoadingService,
	) {}

	get model(): M {
		return this.form.get('model')?.value as M
	}

	ngOnInit(): void {
		this.form.reset({ model: this.initialFormValue() })
	}

	onSubmit(): void {
		if (this.form.valid) {
			const model = this.getModelToSave()
			this.saveModel(model)
		}
	}

	protected getModelToSave(): M {
		return this.model
	}

	protected abstract initialFormValue(): M

	protected abstract action$(model: M): Observable<unknown>

	protected abstract getErrorMessage(err: unknown, model: M): string

	protected abstract getSuccessMessage(model: M): string

	protected showError(message: string, duration: number = 5000): void {
		this.errors$.next(message)
		timer(duration)
			.pipe(first())
			.subscribe(() => this.errors$.next(''))
	}

	private saveModel(model: M): void {
		this.loadingService.start()
		this.action$(model)
			.pipe(
				first(),
				catchError((err) => this.onError(err, model)),
				finalize(() => this.loadingService.stop()),
			)
			.subscribe(() => this.onSuccess(model))
	}

	private onError(err: unknown, model: M): Observable<never> {
		const message = this.getErrorMessage(err, model)
		this.showError(message)
		return throwError(err)
	}

	private onSuccess(model: M): void {
		const message = this.getSuccessMessage(model)
		this.dialogRef.close(message)
	}
}
