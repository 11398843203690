import { ChangeDetectorRef, Directive, Inject, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthService } from '../../modules/auth/auth.service'
import { AppUserRole, userRoleToClaim } from '../../../../domain/user/app-user.model'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Directive({
	selector: '[appHasAnyRole]',
})
export class HasAnyRoleDirective implements OnDestroy {
	constructor(
		@Inject(ViewContainerRef) private readonly viewRef: ViewContainerRef,
		@Inject(TemplateRef) private readonly templateRef: TemplateRef<unknown>,
		@Inject(AuthService) private readonly authService: AuthService,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef
	) {}

	@Input('appHasAnyRole') set roles(allowedRoles: Array<AppUserRole>) {
		const claims = allowedRoles.map((role) => userRoleToClaim.get(role)) as Array<string>
		this.authService
			.hasAnyClaim(claims)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((allowed) => {
				this.viewRef.clear()
				if (allowed) {
					this.renderContent()
				}
				this.cdf.detectChanges()
			})
	}

	ngOnDestroy(): void {
		this.destroyed$.next()
	}

	private renderContent(): void {
		this.viewRef.createEmbeddedView(this.templateRef)
	}

	private readonly destroyed$: Subject<void> = new Subject<void>()
}
