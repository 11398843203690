export class ArrayUtils {
  static isEmpty(array: Array<unknown>): boolean {
    return !array || array.length === 0
  }

  static replaceInArray<T>(arr: Array<T>, indexFn: (item: T) => boolean, values: Array<T> | undefined): void {
    const index = arr.findIndex(indexFn)
    if (index > -1) {
      values ? arr.splice(index, 1, ...values) : arr.splice(index, 1)
    }
  }

  /** @usage array.reduce(ArrayUtils.flattenArrayReducer,[]) */
  static flattenArrayReducer = <T>(acc: Array<T>, current: Array<T>) => [...acc, ...current]
}
