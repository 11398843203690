export class Utils {
	static s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	static guid() {
		return (
			this.s4() +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			this.s4() +
			this.s4()
		)
	}

  static replaceAll(str: string, find: RegExp, replace: (substring: string, ...args: any[]) => string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  static generateDisplayName(key: string, str: string): string {
    let regex = /\s[a-zA-z]/
    let tempStr = str.replace(/[a-zA-z]+\s+-+\s+/, '')
    let displayName = Utils.replaceAll(tempStr, regex, function(s) { return s.replace(' ', '').toUpperCase() })
    displayName = displayName[0].toLowerCase() + displayName.slice(1)
    return key + '.' + displayName
  }
}
