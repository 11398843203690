import { Inject, Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	constructor(@Inject(MatSnackBar) private readonly snackBar: MatSnackBar) {}

	showMessage(message: string, duration: number = 4000): void {
		this.snackBar.open(message, undefined, { duration })
	}

	showErrorMessage(message: string, duration: number = 4000): void {
		this.snackBar.open(message, undefined, { duration, panelClass: 'snackbar-error' })
	}
}
