import { FilterGenerator, generateFilters } from './filters-generator'
import { FirestoreDoc } from './firestore-doc.model'


export abstract class FilterableFirestoreDoc<D extends FilterableFirestoreDoc<D>> extends FirestoreDoc {
  filterKeywords: Array<string> = []

  onCreate(): void {
    super.onCreate()
    this.filterKeywords = generateFilters<D>({ ...this as unknown as D }, this.getFilterGenerators())
  }

  onUpdate(): void {
    super.onUpdate()
    this.filterKeywords = generateFilters<D>({ ...this as unknown as D }, this.getFilterGenerators())
  }

  withoutMetadata(): FilterableFirestoreDoc<D> {
    const { filterKeywords, ...doc } = super.withoutMetadata() as FilterableFirestoreDoc<D>
    return doc as FilterableFirestoreDoc<D>
  }

  protected abstract getFilterGenerators(): Array<FilterGenerator<D>>
}

// tslint:disable-next-line:no-any
export function stripFilterableFireDocAuditProperties<D extends FilterableFirestoreDoc<any>>(doc: D): D {
  const { createdAt, updatedAt, filterKeywords, ...result } = doc
  return result as D
}
