/**
 * Holds every (userCategory: price) pair
 */
export interface PriceCategoryOptions {
  [AppUserPrice.A]: number
  [AppUserPrice.B]: number
  [AppUserPrice.C]: number
  [AppUserPrice.A_EUR]: number
  [AppUserPrice.B_EUR]: number
  [AppUserPrice.C_EUR]: number
}

/**
 * User categories
 * In real use cases, users can only have A, B, C
 * EUR gets concatenated if shopSettings.currency is EUR
 */
export enum AppUserPrice {
  A = 'A',
  B = 'B',
  C = 'C',
  A_EUR = 'A_EUR',
  B_EUR = 'B_EUR',
  C_EUR = 'C_EUR',
}

export enum AppUserCurrency {
  RON = 'RON',
  EUR = 'EUR',
}

export const DefaultPriceCategory = AppUserPrice.C
export const DefaultEurPriceCategory = AppUserPrice.C_EUR
