import { Inject, Injectable } from '@angular/core'
import { FmxErrorsService } from '../../shared/modules/form/errors/errors.service'

@Injectable({ providedIn: 'root' })
export class ValidationErrorResolversService {
	constructor(@Inject(FmxErrorsService) private readonly errorsService: FmxErrorsService) {}

	registerAll(): void {
		this.errorsService.registerResolvers([
			{ errorKey: 'invalid', getMessage: (err, label) => `` }, // default error message to mark a custom ControlValueAccessor as invalid
			{ errorKey: 'required', getMessage: (err, label) => `${label} e obligatoriu de completat` },
			{ errorKey: 'email', getMessage: (err, label) => `Adresa de email nu e valida` },
			{ errorKey: 'pattern', getMessage: (err, label) => `${label} nu este de formă validă`},
			{
				errorKey: 'minlength',
				getMessage: (err: { requiredLength: number; actualLength: number }, label) =>
					`Nr minim de valori pentru ${label}: ${err.requiredLength}`,
			},
			{
				errorKey: 'maxlength',
				getMessage: (err: { requiredLength: number; actualLength: number }, label) =>
					`Nr maxim de valori pentru ${label}: ${err.requiredLength}`,
			},
			{
				errorKey: 'min',
				getMessage: (err: { min: number; actual: number }, label) => `Valoare minima pentru ${label} este ${err.min}`,
			},
			{
				errorKey: 'max',
				getMessage: (err: { max: number; actual: number }, label) => `Valoare maxima pentru ${label} este ${err.max}`,
			},
		])
	}
}
