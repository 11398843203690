import { Injectable } from '@angular/core'
import { PixelService } from '@bloomscorp/ngx-pixel'
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'
import { ShopService } from './shop.service'
import { NgcCookieConsentService } from 'ngx-cookieconsent'
import { distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators'
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs'
import { DestroyAware, untilDestroyed } from '../../shared/utils/destroy-aware.decorator'

@DestroyAware()
@Injectable({
	providedIn: 'root',
})
export class PixelHelperService {
	constructor(
		private readonly pixel: PixelService,
		private readonly shopSettingsService: ShopSettingsService,
		private readonly shopService: ShopService,
		private readonly cookieConsentService: NgcCookieConsentService,
	) {}

	get pixelActive$(): Observable<void> {
		return this.pixelActiveSubject.asObservable().pipe(
			shareReplay(1),
			filter(Boolean),
			map(() => undefined),
			take(1),
		)
	}

	init(): void {
		const hasUserConsented$ = merge(
			this.cookieConsentService.initialized$,
			this.cookieConsentService.statusChange$,
		).pipe(
			map((res) => {
				const status = res?.status ?? undefined
				return status === 'allow' || status === 'dismiss'
			}),
			distinctUntilChanged(),
		)

		const isAllowed$ = combineLatest([
			hasUserConsented$,
			this.shopService.isPublicShopChanges$.pipe(distinctUntilChanged()),
		]).pipe(
			map(([hasUserConsented, isPublicShop]) => hasUserConsented && isPublicShop),
			distinctUntilChanged(),
		)

		combineLatest([
			isAllowed$,
			this.shopSettingsService.shopSettingsChanges$.pipe(
				map((settings) => settings.facebookPixelId),
				distinctUntilChanged(),
			),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([isPixelAllowed, pixelId]) => {
				if (!isPixelAllowed || !pixelId) {
					this.deactivatePixel()
					return
				}

				this.activatePixel(pixelId)
			})
	}

	private deactivatePixel(): void {
		this.pixel.remove()
		this.pixelActiveSubject.next(false)
	}

	private activatePixel(pixelId: string): void {
		this.pixel.remove()
		this.pixel.initialize(pixelId)
		this.pixelActiveSubject.next(true)
	}

	private readonly pixelActiveSubject = new BehaviorSubject<boolean>(false)
}
