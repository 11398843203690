import { FirestoreDoc } from '../../commons/firestore/firestore-doc.model'
import isEqual from 'lodash.isequal'
import { AppUserCurrency, AppUserPrice } from "../price-categories"

export interface ModelSettings {
	price?: number
	order?: number
	hide?: boolean
}

export interface ModelsSettings {
	shoe?: Record<string, ModelSettings>
	material?: Record<string, ModelSettings>
}

/**
 * Settings that get loaded for shop present on /shop/ route
 * - Alternatively, should get loaded for shop owners when they log in
 * {@link ShopSettings.uid} equals to the linked {@link AppUser.uid}
 */
export class ShopSettings extends FirestoreDoc {
	url: string = ''
	primary: string = ''
	secondary: string = ''
	other: string = ''
	background: string = ''
	hidePrice: boolean = false
	logoUrl1: string = ''
	logoUrl2: string = ''
  defaultLang: string = 'ro'
	priceCategory: AppUserPrice = AppUserPrice.C
	currency: AppUserCurrency = AppUserCurrency.RON
	stripeApiKey: string = ''
  facebookPixelId: string = ''
  ownerEmail: string = ''
  ownerCompany: string = ''
  ownerPhone: string = ''
	modelsSettings: ModelsSettings = {}

	equals(other: ShopSettings): boolean {
		return (
			super.equals(other) &&
			this.url === other.url &&
			this.primary === other.primary &&
			this.secondary === other.secondary &&
			this.other === other.other &&
			this.background === other.background &&
			this.hidePrice === other.hidePrice &&
			this.logoUrl1 === other.logoUrl1 &&
			this.logoUrl2 === other.logoUrl2 &&
			this.priceCategory === other.priceCategory &&
			this.currency === other.currency &&
			this.stripeApiKey === other.stripeApiKey &&
			this.facebookPixelId === other.facebookPixelId &&
			this.ownerEmail === other.ownerEmail &&
			this.ownerCompany === other.ownerCompany &&
			this.ownerPhone === other.ownerPhone &&
			isEqual(this.modelsSettings, other.modelsSettings)
		)
	}
}

export function getDefaultShopSettings(): ShopSettings {
	const settings = new ShopSettings()
	settings.primary = '#ff2453'
	settings.secondary = '#17a700'
	settings.other = '#bfbfbf'
	settings.background = '#f5f5f5'
	settings.logoUrl1 = 'logos/logo_default.png'
	settings.logoUrl2 = 'logos/image_login_default.jpg'
	return { ...settings } as ShopSettings
}
