import { FilterableFirestoreDoc } from '../commons/firestore/filterable-fire-doc.model'
import { FilterGenerator, FulltextFilterGenerator } from '../commons/firestore/filters-generator'
import { Color } from './color.model'
import { isEqual } from 'lodash'
import { AppUserCurrency, AppUserPrice, DefaultPriceCategory, PriceCategoryOptions } from './price-categories'
import { Utils } from "../src/app/shared/utils/utils"

export type MaterialType = 'MeshPhysicalMaterial' | string // todo: is this right? if yes, add other types

export class Material extends FilterableFirestoreDoc<Material> {
	name: string = ''
	displayName: string = ''
	price: number = 0
	priceOptions: PriceCategoryOptions = {
		[AppUserPrice.A]: 0,
		[AppUserPrice.B]: 0,
		[AppUserPrice.C]: 0,
		[AppUserPrice.A_EUR]: 0,
		[AppUserPrice.B_EUR]: 0,
		[AppUserPrice.C_EUR]: 0,
	}
	patinabil: boolean = false
	patinat: boolean = false
	thumbSrc: string = ''
	colorOptions: Array<Color> = []
	colorOptionIds: Array<string> = [] // needed for firestore querying
	isExternalColorChange: boolean = false // needed for avoiding infinite loops on firestore triggers between colors -> materials -> shoes
	// 3D properties below
	type: MaterialType = ''
	diffuseSrc: string = ''
	roughnessSrc: string = ''
	metalnessSrc: string = ''
	normalSrc: string = ''
	normalScale: [number, number] = [0, 0] // todo: maybe makes this an object?
	roughness: number = 0
	metalness: number = 0
	clearcoat: number = 0
	clearcoatRoughness: number = 0
	scale: number = 0
	reflectivity: number = 0
	envMapIntensity: number = 0
	aoMapIntensity: number = 0

	static withoutMetadata(material?: Material): Material {
		const clone: Material = Object.assign(new Material(), material ?? {})
		return clone.withoutMetadata()
	}

	getPrice(category: AppUserPrice, currency: AppUserCurrency): number {
		switch (currency) {
			case AppUserCurrency.EUR:
				switch (category) {
					case AppUserPrice.A:
						return this.priceOptions[AppUserPrice.A_EUR]
					case AppUserPrice.B:
						return this.priceOptions[AppUserPrice.B_EUR]
					case AppUserPrice.C:
						return this.priceOptions[AppUserPrice.C_EUR]
					default:
						return this.priceOptions[DefaultPriceCategory]
				}
			default:
				return this.priceOptions[category]
		}
	}

	equals(other: Material): boolean {
		return (
			super.equals(other) &&
			this.name === other.name &&
			this.type === other.type &&
			this.patinabil === other.patinabil &&
			this.patinat === other.patinat &&
			this.price === other.price &&
			this.thumbSrc === other.thumbSrc &&
			isEqual(this.colorOptions, other.colorOptions) &&
			this.diffuseSrc === other.diffuseSrc &&
			this.roughnessSrc === other.roughnessSrc &&
			this.metalnessSrc === other.metalnessSrc &&
			this.normalSrc === other.normalSrc &&
			isEqual(this.normalScale, other.normalScale) &&
			this.roughness === other.roughness &&
			this.metalness === other.metalness &&
			this.clearcoat === other.clearcoat &&
			this.clearcoatRoughness === other.clearcoatRoughness &&
			this.scale === other.scale &&
			this.reflectivity === other.reflectivity &&
			this.envMapIntensity === other.envMapIntensity &&
			this.aoMapIntensity === other.aoMapIntensity
		)
	}

	onCreate(): void {
	  this.displayName = Utils.generateDisplayName('material', this.name)
		this.colorOptionIds = this.colorOptions.map((color) => color.uid)
		super.onCreate()
	}

	onUpdate(): void {
    this.displayName = Utils.generateDisplayName('material', this.name)
		this.colorOptionIds = this.colorOptions.map((color) => color.uid)
		this.isExternalColorChange = false
		super.onUpdate()
	}

	withoutMetadata(): Material {
		const { colorOptionIds, ...doc } = super.withoutMetadata() as Material
		return doc as Material
	}

	protected getFilterGenerators(): Array<FilterGenerator<Material>> {
		return [new FulltextFilterGenerator((material) => material.name)]
	}
}
