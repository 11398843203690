import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import {
	AbstractControl,
	UntypedFormControl,
	UntypedFormGroup,
	NgControl,
	ValidatorFn,
	Validators,
} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ShoeCalapod, ShoeOrder } from 'domain/order.model'
import { AppUserCurrency } from 'domain/price-categories'
import { Shoe } from 'domain/shoe.model'
import { forkJoin, Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { CartService } from 'src/app/modules/dashboard/shop/services/cart.service'
import { ConfirmationDialog } from '../dialog/confirmation.dialog'
import { ControlValueAccessor } from '../modules/form/core/control-value-accessor'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-shoe-order-form',
	template: `
		<div [formGroup]="control" fxLayoutAlign="space-between center" fxLayoutGap="0px grid">
			<div fxFlex="22" class="article-image-container">
				<img *ngIf="image | async as url" [src]="url" width="140px" />
			</div>
			<div class="name-column">{{ shoe.displayName | translate }}</div>
			<div class="size-column">{{ shoeSize }}</div>
			<div class="calapod-column">{{ shoeCalapod }}</div>
			<div class="quantity-column">
				<div class="quantity-input" fxLayoutAlign="space-between center" fxLayoutGap="0px grid">
					<button mat-button type="button" class="dialog-exit-button" (click)="decrementQuantity()">
						<mat-icon>remove</mat-icon>
					</button>

					{{ control.value.amount }}
					<!--					<app-number-input-->
					<!--						class="center-input-text"-->
					<!--            style="flex-basis: 26%"-->
					<!--						formControlName="amount"-->
					<!--						[min]="minValue"-->
					<!--						[readonly]="true"-->
					<!--					></app-number-input>-->
					<button mat-button type="button" class="dialog-exit-button" (click)="incrementQuantity()">
						<mat-icon>add</mat-icon>
					</button>
				</div>
			</div>
			<div class="price-column">{{ value_sell }} {{ currency | async }}</div>
			<div class="delete-button">
				<button mat-button type="button" class="dialog-exit-button" (click)="openConfirmDialog()">
					<mat-icon>cancel</mat-icon>
				</button>
			</div>
		</div>
		<hr />
	`,
	styleUrls: ['./shoe-orders-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoeOrderFormComponent extends ControlValueAccessor<ShoeOrder> implements OnInit {
	image: Observable<string> = of('')
	orderId: string = ''
	minValue: number = 1
	@Input() currency: Observable<AppUserCurrency | undefined> = of(AppUserCurrency.RON)
	@Output() objectRemoved: EventEmitter<string> = new EventEmitter<string>()

	readonly control: UntypedFormGroup = new UntypedFormGroup({
		id: new UntypedFormControl(''),
		shoeSize: new UntypedFormControl(''),
		shoeCalapod: new UntypedFormControl(''),
		description: new UntypedFormControl(''),
		value: new UntypedFormControl(''),
		value_sell: new UntypedFormControl(''),
		amount: new UntypedFormControl(''),
		shoe: new UntypedFormControl(''),
	})

	constructor(
		@Inject(NgControl) protected readonly controlDirective: NgControl,
		@Inject(AngularFireStorage) private readonly storage: AngularFireStorage,
		@Inject(CartService) private readonly cartService: CartService,
		@Inject(MatDialog) private readonly dialog: MatDialog,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef,
		@Inject(TranslateService) private readonly translateService: TranslateService,
	) {
		super(controlDirective)
	}

	get id(): string {
		return (this.control.get('id') as AbstractControl).value
	}

	get shoeSize(): string {
		return (this.control.get('shoeSize') as AbstractControl).value
	}

	get shoeCalapod(): ShoeCalapod {
		return (this.control.get('shoeCalapod') as AbstractControl).value
	}

	get description(): string {
		return (this.control.get('description') as AbstractControl).value
	}

	get value(): string {
		return (this.control.get('value') as AbstractControl).value
	}

	get value_sell(): string {
		return (this.control.get('value_sell') as AbstractControl).value
	}

	get amount(): number {
		return (this.control.get('amount') as AbstractControl).value
	}

	get shoe(): Shoe {
		return (this.control.get('shoe') as AbstractControl).value
	}

	ngOnInit(): void {
		super.ngOnInit()
		this.cartService
			.getPendingOrderUid()
			.pipe(take(1))
			.subscribe((orderId) => {
				this.image = this.storage.ref(`uploads/snapshots/${orderId}${this.id}1.png`).getDownloadURL()
			})
	}

	openConfirmDialog() {
		this.translateService.get(this.shoe.displayName).subscribe((shoeName) => {
			forkJoin([
				this.translateService.get('dialog.shoopingCartRemove'),
				this.translateService.get('dialog.shoopingCartRemoveText', { name: shoeName }),
			]).subscribe(([title, question]) => {
				const dialogRef = this.dialog.open(ConfirmationDialog, {
					data: { title: title, question: question },
					width: '650px',
					autoFocus: false,
				})
				dialogRef.afterClosed().subscribe((remove) => {
					if (remove) {
						this.objectRemoved.emit(this.id)
						this.cartService.removeProduct(this.id)
						this.cdf.markForCheck()
					}
				})
			})
		})
	}

	incrementQuantity(): void {
		const newAmount = this.amount + 1
		this.control.get('amount')?.setValue(newAmount)
		this.cartService.setQty(this.id, newAmount)
	}

	decrementQuantity(): void {
		const newAmount = this.amount - 1
		if (newAmount) {
			this.control.get('amount')?.setValue(newAmount)
			this.cartService.setQty(this.id, newAmount)
		} else {
			this.openConfirmDialog()
		}
	}
}
