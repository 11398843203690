import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core"
import { AppInitService } from "./app-init.service"
import { TranslateService } from "@ngx-translate/core"
import { LoadingService } from "./core/services/loading.service"

@Component({
  selector: "app-root",
  template: `
    <div class="progress-bar-container" *ngIf="loadingService.loading$ | async">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      .progress-bar-container {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        /*background-color: rgba(0,0,0,0.7);*/
        z-index: 1001; /* 1000 is used by the overlay container */
      }

      cc-window {
        opacity: 1;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(AppInitService) appInitService: AppInitService,
    @Inject(LoadingService) readonly loadingService: LoadingService,
    @Inject(TranslateService) public translateService: TranslateService
  ) {
    appInitService.init()
  }

  ngOnInit(): void {}
}
