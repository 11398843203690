import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms'
import { ControlValueAccessor } from '../../../../../shared/modules/form/core/control-value-accessor'

@Component({
	selector: 'app-order-seller-confirm',
	template: `
		<div [formGroup]="control" fxLayout="column">
			<app-input formControlName="password" type="password" label="Parola"></app-input>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSellerConfirmComponent extends ControlValueAccessor<{ password: string }> {
	readonly control: UntypedFormGroup = new UntypedFormGroup({
		password: new UntypedFormControl('', [Validators.required]),
	})

	constructor(@Inject(NgControl) protected readonly ngControl: NgControl) {
		super(ngControl)
	}
}
