import { Injectable } from '@angular/core'
import { ValidationErrorResolversService } from './core/services/validation-error-resolvers.service'
import { AuthService } from './modules/auth/auth.service'
import { AppUser } from '../../domain/user/app-user.model'
import { UserTermsDialog } from './modules/user-terms/user-terms.dialog'
import { MatDialog } from '@angular/material/dialog'
import { filter } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import { NavigationService } from './core/services/navigation.service'
import { ShopSettingsService } from './modules/auth/shop-settings.service'
import { ShopService } from './core/services/shop.service'
import { TranslateService } from '@ngx-translate/core'
import { DestroyAware, untilDestroyed } from './shared/utils/destroy-aware.decorator'
import { GdprService } from './gdpr/gdpr.service'
import { PixelHelperService } from './core/services/pixel-helper.service'
import { PricingService } from './core/services/pricing.service'
import { SnackbarService } from './core/services/snackbar.service'

@DestroyAware()
@Injectable({
	providedIn: 'root',
})
export class AppInitService {
	constructor(
		private readonly validationErrorResolvers: ValidationErrorResolversService,
		private readonly shopSettingsService: ShopSettingsService,
		private readonly shopService: ShopService,
		private readonly navigationService: NavigationService,
		private readonly authService: AuthService,
		private readonly dialog: MatDialog,
		private readonly translateService: TranslateService,
		private readonly gdprService: GdprService,
		private readonly pricingService: PricingService,
		private readonly pixelHelperService: PixelHelperService,
		private readonly snackbarService: SnackbarService,
	) {}

	init(): void {
		this.initTranslations()

		this.gdprService.init()

		this.pixelHelperService.init()

		this.validationErrorResolvers.registerAll()

		combineLatest([this.shopSettingsService.shopSettingsChanges$, this.shopService.isPublicShop$])
			.pipe(untilDestroyed(this))
			.subscribe(([settings, isPublicShop]) => {
				if (isPublicShop && !settings.stripeApiKey) {
					this.navigationService.goToPageNotFound()
					return
				}
				this.shopSettingsService.applySettings(settings)
			})

		this.authService.appUserChanges$
			.pipe(
				untilDestroyed(this),
				filter((user) => !!(user && !user.agreed)),
			)
			.subscribe((user) => {
				this.showUserTermsDialog(user as AppUser)
			})
		// this.checkBrowser()
	}

	private initTranslations(): void {
		this.translateService.addLangs(['ro', 'en', 'it', 'de'])
		const browserLang = this.translateService.getBrowserLang()
		const langPref = localStorage.getItem('langPref')
		if (langPref) {
			this.translateService.use(langPref)
		} else {
			this.translateService.use(browserLang?.match(/ro|en|it|de/) ? browserLang : 'en')
		}
	}

	private showUserTermsDialog(user: AppUser): void {
		const dialogRef = this.dialog.open(UserTermsDialog, {
			data: user,
			position: { top: '2%' },
			width: '700px',
			disableClose: true,
		})
		dialogRef.afterClosed().subscribe()
	}

	//check if browser is safari
	private checkBrowser() {
		if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
			this.snackbarService.showErrorMessage('We recommend using Chrome browser for the best experience.', 15000)
		}
	}
}
