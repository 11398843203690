import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './modules/auth/auth.service'

@Injectable({ providedIn: 'root' })
export class LogoutGuard  {
	constructor(
		@Inject(AuthService) private readonly authService: AuthService,
		@Inject(Router) private readonly router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService.logout(false).then(() => this.router.parseUrl('auth'))
	}
}
