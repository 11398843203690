import { Injectable } from '@angular/core'
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators'
import { ArrayUtils } from '../../../../commons/array.utils'

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  static readonly PUBLIC_SHOP_URL_BASE_PATH = 'shop'

  readonly publicShopUrlChanges$: Observable<string | null> = this.router.events.pipe(
    // @ts-ignore
    filter((event: Event) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => this.extractPublicShopUrl(event.url)),
    shareReplay(1)
  )
  readonly publicShopUrl$: Observable<string | null> = this.publicShopUrlChanges$.pipe(take(1))

  readonly isPublicShopChanges$: Observable<boolean> = this.publicShopUrlChanges$.pipe(
    map((url) => !!url),
    shareReplay(1)
  )
  readonly isPublicShop$: Observable<boolean> = this.isPublicShopChanges$.pipe(take(1))

  readonly routeShopUrlChanges$: Observable<string | null> = combineLatest([
    this.publicShopUrlChanges$,
    this.route.queryParamMap.pipe(map((paramsMap) => paramsMap.get('url'))),
  ]).pipe(
    map(([publicShopUrl, paramShopUrl]) => paramShopUrl || publicShopUrl || null),
    distinctUntilChanged(),
    shareReplay(1)
  )
  readonly routeShopUrl$: Observable<string | null> = this.routeShopUrlChanges$.pipe(take(1))

  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

  /** Example of public shop url: /{@link ShopService.PUBLIC_SHOP_URL_BASE_PATH}/shopUrl[/...] */
  private extractPublicShopUrl(pathUrl: string): string | null {
    const basePathWithSlashes = `/${ShopService.PUBLIC_SHOP_URL_BASE_PATH}/`
    if (!pathUrl.includes(basePathWithSlashes)) {
      return null
    }
    const endOfBasePathWithSlashesIndex = pathUrl.indexOf(basePathWithSlashes) + basePathWithSlashes.length
    const afterBaseUrl = pathUrl.slice(endOfBasePathWithSlashesIndex)

    const shopUrlEndingIndexes = [afterBaseUrl.indexOf('/'), afterBaseUrl.indexOf('?')].filter((index) => index > 0)

    if (!ArrayUtils.isEmpty(shopUrlEndingIndexes)) {
      return afterBaseUrl.slice(0, shopUrlEndingIndexes[0])
    }

    return afterBaseUrl
  }
}
