import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { shareReplay } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class NavbarService {
	/** not the best way to do it, but the fastest one, if there is need for more external buttons, refactor to a component portal approach */
	readonly placeOrderSubject: Subject<void> = new Subject<void>()

	setTitle(title: string): void {
		this.titleSubject.next(title)
	}

	private readonly titleSubject: Subject<string> = new Subject<string>()
	// tslint:disable-next-line:member-ordering
	readonly title$: Observable<string> = this.titleSubject.asObservable().pipe(shareReplay(1))
}
