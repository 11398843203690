import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { MaterialControlValueAccessor } from '../core/material-cva'
import { UntypedFormControl, NgControl } from '@angular/forms'
import { ErrorMessageResolver } from 'src/app/shared/modules/form/errors/error-message-resolver'

@Component({
	selector: 'app-input',
	template: `
		<mat-form-field [appearance]="appearance" class="w-100">
			<mat-label *ngIf="label">{{ label }}</mat-label>
			<input
				matInput
				[type]="type"
				placeholder="{{ placeholder }}"
				[formControl]="control"
				[errorStateMatcher]="errorStateMatcher"
				[readonly]="readonly"
			/>

			<button *ngIf="icon" mat-icon-button matSuffix aria-label="Suffix btn" (click)="iconClick.emit()">
				<mat-icon>{{ icon }}</mat-icon>
			</button>

			<mat-error>
				<fmx-errors
					[control]="externalControl"
					[resolvers]="resolvers"
					[controlLabel]="errorLabel || label || placeholder"
				></fmx-errors>
			</mat-error>
		</mat-form-field>
	`,
	styleUrls: ['./input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends MaterialControlValueAccessor {
	@Input() readonly type: 'text' | 'number' | 'password' | 'email' = 'text'
	@Input() readonly resolvers: Array<ErrorMessageResolver> = []
	@Input() readonly icon: string = ''
	@Input() readonly: boolean = false
	@Output() readonly iconClick: EventEmitter<void> = new EventEmitter<void>()
	readonly control: UntypedFormControl = new UntypedFormControl()

	constructor(@Inject(NgControl) protected readonly controlDirective: NgControl) {
		super(controlDirective)
	}
}
