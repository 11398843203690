<!-- <div fxLayout fxLayoutAlign="center center" class="h-100">
    <mat-card class="p-4" style="width: 350px">
      <router-outlet></router-outlet>
    </mat-card>
  </div> -->

<div fxLayout fxLayoutAlign="center center" class="h-100">
	<div class="row col-md-12" style="height: 100%">
		<div class="col-md-5 login-user" style="width: 100%">
			<!-- <img class="login-logo" src="assets/images/logo.png" /> -->
			<img *ngIf="logoUrl1$ | async as url" class="login-logo" [src]="url" />
			<router-outlet></router-outlet>
		</div>
		<!-- <div class="col-md-7 login-img" style="background-image: url('assets/images/login.png');"> -->
		<div *ngIf="logoUrl2$ | async as url" class="col-md-7 login-img" [style.background]="'url(' + url + ')'"></div>
	</div>
</div>
