import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { AbstractFormDialog } from '../dialog/abstract-form-dialog'
import { LoadingService } from '../../core/services/loading.service'
import { ShopSettings } from '../../../../domain/user/shop-settings.model'
import { ShopSettingsFirestoreService } from '../../core/firestore-services/shop-settings-firestore.service'

@Component({
	selector: 'app-settings-edit-dialog',
	template: `
		<form [formGroup]="form" (fmxSubmit)="onSubmit()">
			<h2 matDialogTitle>{{ 'tab.setari' | translate }}</h2>

			<mat-dialog-content>
				<app-settings-form formControlName="model"></app-settings-form>
				<mat-error *ngIf="errors$ | async as errors" class="mb-3">{{ errors }}</mat-error>
			</mat-dialog-content>

			<div mat-dialog-actions>
				<button mat-button mat-dialog-close class="btn-custom-default">{{ 'actiune.inchide' | translate }}</button>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					class="btn-custom-primary"
					[disabled]="loadingService.loading$ | async"
				>
					{{ 'actiune.actualizeaza' | translate }}
				</button>
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsEditDialog extends AbstractFormDialog<ShopSettings, SettingsEditDialog> {
	constructor(
		@Inject(MatDialogRef) protected readonly dialogRef: MatDialogRef<SettingsEditDialog>,
		@Inject(LoadingService) readonly loadingService: LoadingService,
		@Inject(MAT_DIALOG_DATA) readonly settings: ShopSettings,
		private readonly shopSettingsFirestoreService: ShopSettingsFirestoreService,
	) {
		super(dialogRef, loadingService)
	}

	protected initialFormValue(): ShopSettings {
		return this.settings
	}

	protected action$(model: ShopSettings): Observable<unknown> {
		return of(this.shopSettingsFirestoreService.update(model))
	}

	protected getErrorMessage(err: unknown, model: ShopSettings): string {
		return `Nu s-au putut actualiza setarile magazinului`
	}

	protected getSuccessMessage(model: ShopSettings): string {
		return `Setarile magazinului au fost actualizate!`
	}
}
