import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Directive,
	EventEmitter,
	Inject,
	Input,
	Output,
	TemplateRef,
} from '@angular/core'
import { MaterialControlValueAccessor } from '../core/material-cva'
import { UntypedFormControl, NgControl } from '@angular/forms'
import { MatOptionSelectionChange } from '@angular/material/core'

@Directive({ selector: '[appSelectOption]' })
export class SelectOptionTemplateDirective {}

@Directive({ selector: '[appControlErrors]' })
export class ControlErrorsTemplateDirective {}

export interface OptionSelectedEvent<T> {
	value: T
	selected: boolean
	isUserInput: boolean
}

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> extends MaterialControlValueAccessor {
	@Input() items: Array<unknown> = []
	@Input() readonly labelOrder: string = ''
	@Input() readonly multiple: boolean = false
	@Input() readonly valueProperty: string | null = null
	@Input() readonly panelClass: string = ''
	@Input() readonly optionClass: string = ''

	@ContentChild(SelectOptionTemplateDirective, { read: TemplateRef, static: true })
	readonly optionTemplate: TemplateRef<unknown> | undefined
	@ContentChild(ControlErrorsTemplateDirective, { read: TemplateRef, static: true })
	readonly errorsTemplate: TemplateRef<unknown> | undefined

	@Output() readonly selectionChange: EventEmitter<unknown> = new EventEmitter<unknown>()
	@Output() readonly optionSelectionChange: EventEmitter<OptionSelectedEvent<T>> = new EventEmitter<
		OptionSelectedEvent<T>
	>()
	readonly control: UntypedFormControl = new UntypedFormControl()

	constructor(@Inject(NgControl) protected readonly controlDirective: NgControl) {
		super(controlDirective)
	}

	@Input() readonly compareWith: (o1: T, o2: T) => boolean = (o1: T, o2: T) => o1 === o2

	// tslint:disable-next-line:no-any
	getValue(item: any): unknown {
		return this.valueProperty ? item[this.valueProperty] : item
	}

	onOptionSelected(event: MatOptionSelectionChange): void {
		this.optionSelectionChange.emit({
			value: event.source.value,
			selected: event.source.selected,
			isUserInput: event.isUserInput,
		})
	}
}
