import { ErrorStateMatcher } from '@angular/material/core'
import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms'
import { FormUtils } from './form.utils'

export class MaterialErrorStateMatcher implements ErrorStateMatcher {
	constructor(private readonly externalControl: AbstractControl) {}

	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return FormUtils.showControlErrors(this.externalControl, form)
	}
}
