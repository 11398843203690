import { FilterableFirestoreDoc } from '../../commons/firestore/filterable-fire-doc.model'
import { FilterGenerator, FulltextFilterGenerator } from '../../commons/firestore/filters-generator'

export enum AppUserRole {
	Founder = 'Founder', // Cel mai cel, nu apare in lista de clienti -> nu poate fi dezactivat decat manual
	Admin = 'Admin',
	BusinessClient = 'BusinessClient',
	Customer = 'Customer', // Are access doar la dashboard si configurator
	Editor = 'Editor', // Admin + poate adauga pantofi si materiale
	Financial = 'Financial', // Are access doar la dashboard, comenzi, facturi
	Seller = 'Seller', // Vanzator al business client
	Demo = 'Demo', // dashboard + configurator, fara posibilitate de a plasa comanda
	SalesAgent = 'SalesAgent', // poate adauga BusinessClients noi
}

export enum AppUserShopType {
	// de lamurit cum se inregistreaza
	Public = 'Public',
	Private = 'Private',
	Both = 'Both',
}

export enum AppUserPayment {
	Online = 'Online',
	Offline = 'Offline',
}

export const userRoleToClaim: Map<AppUserRole, string> = new Map<AppUserRole, string>([
	[AppUserRole.Admin, 'admin'],
	[AppUserRole.BusinessClient, 'businessClient'],
	[AppUserRole.Customer, 'customer'],
	[AppUserRole.Editor, 'editor'],
	[AppUserRole.Financial, 'financial'],
	[AppUserRole.Founder, 'founder'],
	[AppUserRole.Seller, 'seller'],
	[AppUserRole.Demo, 'demo'],
	[AppUserRole.SalesAgent, 'salesAgent'],
])

export interface UserVO {
	uid: string
	name: string
	owner?: string
}

export interface AdminSettings {
	lastUsedBusinessNumber: number
}

export interface BusinessClientSettings {
	lastUsedSellerNumber?: number
	lastOrderNumber?: number
}

export interface AppUserPrivateData {
	/** uid of owner user */
	stripePrivateKey?: string
	stripeWebhookSecret?: string
}

export class AppUser extends FilterableFirestoreDoc<AppUser> {
	email: string = ''
	/** @deprecated to be removed after seller add refactoring */
	password: string = '' // used only for saving a new user, never saved into firestore

	name: string = ''
	contact: string = ''
	phone: string = ''
	logoUrl: string = ''
	role: AppUserRole = AppUserRole.BusinessClient
	disabled: boolean = false
	agreed: string = ''
	lastCheck: string = ''

	/**
	 * Uid reference to another AppUser which "owns" the current one.
	 * There are multiple cases when this happens:
	 * 1. Current user has role ${@link AppUserRole.Seller}, the owner is the uid of the businessClient (owner of the shop)
	 * 2. For when Vendor user role will be implemented
	 * 3. empty in all other cases */
	owner: UserVO = {
		uid: '',
		name: '',
		owner: '',
	}

	/** Used for generating order numbers. Only set on users with role {@link AppUserRole.BusinessClient} */
	businessNumber?: number

	/** Used for generating order numbers. Only set on users with roles: {@link AppUserRole.Customer} or {@link AppUserRole.BusinessClient} */
	sellerNumber?: number

	/** Matches the uid of the current user. Exception for {@link AppUserRole.Seller} where this equals the {@link AppUser.owner} */
	companyUid: string = ''

	/** Matches the uid of the current user. Exception for {@link AppUserRole.Seller} where this equals the {@link AppUser.owner} */
	settingsUid: string | UserVO = ''

	static toUserVO(user: AppUser): UserVO {
		return { uid: user.uid, name: user.name, owner: user.owner.uid ?? '' }
	}

	equals(other: AppUser): boolean {
		return (
			super.equals(other) &&
			this.email === other.email &&
			this.name === other.name &&
			this.contact === other.contact &&
			this.role === other.role &&
			this.phone === other.phone &&
			this.disabled === other.disabled
		)
	}

	onCreate(): void {
		super.onCreate()
		this.password = ''
	}

	onUpdate(): void {
		super.onUpdate()
		this.password = ''
	}

	protected getFilterGenerators(): Array<FilterGenerator<AppUser>> {
		return [new FulltextFilterGenerator((user) => user.name)]
	}
}
