import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ColDef, PartialColDef } from '../col-def.model'
import { AbstractColumnComponent, TableColumn } from './column'

@Component({
  template: `
    <ng-container *ngIf="value">
      {{ value | translate }}
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatedTextColumnComponent<T extends object> extends AbstractColumnComponent<T, never> {
  get value(): string | null {
    if (!(this.row && this.def?.property in this.row)) {
      return null
    }
    return `${this.row[this.def?.property as keyof T]}`
  }
}

export function TranslatedTextColumn<T extends object>(
  def: PartialColDef<T, TranslatedTextColumnComponent<T>>
): ColDef<T, TranslatedTextColumnComponent<T>> {
  return TableColumn<T, TranslatedTextColumnComponent<T>>({ ...def, component: TranslatedTextColumnComponent })
}
