import { Inject, Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { ShopSettingsFirestoreService } from '../../core/firestore-services/shop-settings-firestore.service'
import { getDefaultShopSettings, ShopSettings } from '../../../../domain/user/shop-settings.model'
import { Observable } from 'rxjs'
import { map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'
import { ShopService } from '../../core/services/shop.service'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { AppUserRole, UserVO } from '../../../../domain/user/app-user.model'

@Injectable({
	providedIn: 'root',
})
export class ShopSettingsService {
	readonly currentUserShopSettingsChanges$: Observable<ShopSettings | null> = this.authService.appUserChanges$.pipe(
		switchMap((user) =>
			this.shopSettingsFirestoreService.observeValues(
				user?.role === AppUserRole.Seller
					? ((user?.settingsUid as UserVO).uid as string)
					: (user?.settingsUid as string)
			)
		),
		shareReplay(1)
	)
	readonly currentUserShopSettings$: Observable<ShopSettings | null> = this.currentUserShopSettingsChanges$.pipe(
		take(1)
	)

	/** Returns current shop settings
	 * It searches for settings in this order:
	 * 1. If route matches a public shop, return its settings
	 * 2. If route is private, return current user's shop-settings
	 * 3. If user is not logged in, return default shop-settings (todo: check if this is needed - might never be the case)
	 * 4. If role is seller, settingsUid is a {@link UserVO} so we access .uid
	 */
	readonly shopSettingsChanges$: Observable<ShopSettings> = this.shopService.routeShopUrlChanges$.pipe(
		switchMap((shopUrl) =>
			shopUrl ? this.shopSettingsFirestoreService.getByShopUrl(shopUrl) : this.currentUserShopSettingsChanges$
		),
		map((settings) => settings ?? getDefaultShopSettings()),
		shareReplay(1)
	)
	readonly shopSettings$: Observable<ShopSettings> = this.shopSettingsChanges$.pipe(take(1))

	readonly logoUrl1$: Observable<string> = this.shopSettingsChanges$.pipe(
		mergeMap((settings) => this.storage.ref(settings.logoUrl1 || getDefaultShopSettings().logoUrl1).getDownloadURL()),
		shareReplay(1)
	)
	readonly logoUrl2$: Observable<string> = this.shopSettingsChanges$.pipe(
		mergeMap((settings) => this.storage.ref(settings.logoUrl2 || getDefaultShopSettings().logoUrl2).getDownloadURL()),
		shareReplay(1)
	)

	constructor(
		private readonly shopService: ShopService,
		private readonly authService: AuthService,
		private readonly shopSettingsFirestoreService: ShopSettingsFirestoreService,
		private readonly storage: AngularFireStorage,
		@Inject(DOCUMENT) private readonly document: Document
	) {}

	applySettings(settings: ShopSettings): void {
		this.document.documentElement.style.setProperty(
			'--aps-color-primary',
			settings.primary || this.defaultSettings.primary
		)
		this.document.documentElement.style.setProperty(
			'--aps-color-secondary',
			settings.secondary || this.defaultSettings.secondary
		)
		this.document.documentElement.style.setProperty('--aps-color-other', settings.other || this.defaultSettings.other)
		this.document.documentElement.style.setProperty(
			'--aps-color-background',
			settings.background || this.defaultSettings.background
		)
	}

	private readonly defaultSettings: ShopSettings = getDefaultShopSettings()
}
