import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core'

class LetContext<T> {
	constructor(private readonly dir: LetDirective<T>) {}

	get ngLet(): T {
		return this.dir.ngLet
	}
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[ngLet]' })
export class LetDirective<T> {
	@Input() ngLet!: T

	constructor(
		@Inject(ViewContainerRef) viewContainer: ViewContainerRef,
		@Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>
	) {
		viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this))
	}
}
