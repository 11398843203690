import { InjectableType, ɵComponentType as ComponentType, ɵDirectiveType as DirectiveType } from '@angular/core'
import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const DECORATOR_APPLIED: unique symbol = Symbol('__decorated')

const DESTROYED$: unique symbol = Symbol('__destroyed$')

function missingDecorator<T>(type: InjectableType<T> | DirectiveType<T> | ComponentType<T>): boolean {
	return !type.prototype[DECORATOR_APPLIED]
}

function ensureClassIsDecorated(instance: any, helperMethodName: string): never | void {
	if (missingDecorator(instance.constructor)) {
		throw new Error(
			`'${helperMethodName}' cannot be used inside class '${instance.constructor.name}' which is not decorated with @DestroyAware()`
		)
	}
}

export function untilDestroyed<S, T>(instance: T): MonoTypeOperatorFunction<S> {
	return (source: Observable<S>) => {
		ensureClassIsDecorated(instance, 'untilDestroyed operator')
		// @ts-ignore
		if (!instance[DESTROYED$]) {
			// @ts-ignore
			instance[DESTROYED$] = new Subject<void>()
		}
		// @ts-ignore
		return source.pipe(takeUntil(instance[DESTROYED$]))
	}
}

function decorateNgOnDestroy(ngOnDestroy: (() => void) | null | undefined): unknown {
	return function (this: any) {
		if (ngOnDestroy) {
			ngOnDestroy.call(this)
		}

		if (this[DESTROYED$]) {
			this[DESTROYED$].next()
			this[DESTROYED$].complete()
		}
		this[DESTROYED$] = null

		this[DECORATOR_APPLIED] = false
	}
}

function decorateProviderDirectiveOrComponent<T>(type: InjectableType<T> | DirectiveType<T> | ComponentType<T>): void {
	type.prototype.ngOnDestroy = decorateNgOnDestroy(type.prototype.ngOnDestroy)
}

export function DestroyAware(): ClassDecorator {
	return (type: any) => {
		decorateProviderDirectiveOrComponent(type)
		type.prototype[DECORATOR_APPLIED] = true
	}
}
