import { Injectable } from '@angular/core'
import { map, switchMap, take } from 'rxjs/operators'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { OrdersFirestoreService } from 'src/app/core/firestore-services/orders-firestore.service'
import { Order, ShoeOrder } from 'domain/order.model'
import { ShopSettingsService } from 'src/app/modules/auth/shop-settings.service'
import { FileUploadService } from 'src/app/shared/services/file-upload.service'
import { FileUpload } from 'domain/fileupload'
import { PixelService } from '@bloomscorp/ngx-pixel'
import { PixelHelperService } from '../../../../core/services/pixel-helper.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
	providedIn: 'root',
})
export class CartService {
	constructedOrder = new Order()

	constructor(
		private readonly ordersFirestoreService: OrdersFirestoreService,
		private uploadService: FileUploadService,
		private readonly shopSettingsService: ShopSettingsService,
		private readonly pixelHelperService: PixelHelperService,
		private readonly pixel: PixelService,
		private readonly translateService: TranslateService,
	) {
		this.loadCart()
	}

	addShoe(shoeOrder: ShoeOrder) {
		this.loadCart()
		this.constructedOrder.shoes.push(shoeOrder)
		this.saveCart()
		this.translateService.get(shoeOrder.shoe.displayName).subscribe((shoeName) =>
			this.pixel.track('AddToCart', {
				content_name: shoeName,
				content_type: 'product',
			}),
		)
	}

	getCart() {
		return this.orderCart$.pipe(map((order) => order.shoes))
	}

	getPendingOrder() {
		return this.constructedOrder
	}

	loadCart() {
		this.shopSettingsService.shopSettings$.subscribe((settings) => {
			const cartKey = 'cys|' + settings.url
			if (localStorage.getItem(cartKey) == null) {
				this.constructedOrder.uid = this.ordersFirestoreService.generateUid()
				this.constructedOrder.shoes = []
				this.saveCart()
			} else {
				this.constructedOrder = JSON.parse(localStorage.getItem(cartKey) as string)
				this.cartSubject.next(this.constructedOrder)
			}
		})
	}

	saveCart() {
		this.shopSettingsService.shopSettings$.subscribe((settings) => {
			const cartKey = 'cys|' + settings.url
			localStorage.setItem(cartKey, JSON.stringify(this.constructedOrder))
			this.cartSubject.next(this.constructedOrder)
		})
	}

	clearCart() {
		this.shopSettingsService.shopSettings$.subscribe((settings) => {
			const cartKey = 'cys|' + settings.url
			localStorage.removeItem(cartKey)
			this.loadCart()
		})
	}

	getDistinctAmount() {
		this.loadCart()
		return this.orderCart$.pipe(
			map((order) => {
				return order.shoes.length
			}),
		)
	}

	getTotalAmount() {
		return this.orderCart$.pipe(
			map((order) => {
				return order.shoes.map((shoe) => shoe.amount).reduce((amount, total) => amount + total, 0)
			}),
		)
	}

	getTotaSellValue() {
		return this.orderCart$.pipe(
			map((order) => {
				return order.shoes
					.map((shoe) => shoe.amount * shoe.value_sell)
					.reduce((value_sell, total) => value_sell + total, 0)
			}),
		)
	}

	getOrderNumbers() {
		return forkJoin([
			this.getTotalAmount().pipe(take(1)),
			this.getTotaSellValue().pipe(take(1)),
			this.getTotalValue().pipe(take(1)),
		]).pipe(
			map(([totalShoes, totalSellValue, totalValue]) => {
				return {
					totalShoes: totalShoes,
					totalSellValue: totalSellValue,
					totalValue: totalValue,
				}
			}),
		)
	}

	getTotalValue() {
		return this.orderCart$.pipe(
			map((order) => {
				return order.shoes.map((shoe) => shoe.amount * shoe.value).reduce((value, total) => value + total, 0)
			}),
		)
	}

	getPendingOrderUid() {
		return this.orderCart$.pipe(map((order) => order.uid))
	}

	getSubmittedOrder(uid: string): Observable<Order | null> {
		return this.shopSettingsService.shopSettings$.pipe(
			switchMap((shopSettings) => this.ordersFirestoreService.getValue(uid, { userUid: shopSettings.uid })),
		)
	}

	removeProduct(id: string) {
		this.loadCart()
		const index = this.constructedOrder.shoes.findIndex((shoeOrder) => shoeOrder.id == id)
		const fileUpload1: FileUpload = {
			key: '',
			name: this.constructedOrder.uid + this.constructedOrder.shoes[index].id + 1 + '.png',
			url: '',
			file: new File([], ''),
		}
		this.uploadService.deleteFileUpload(fileUpload1)

		const fileUpload2: FileUpload = {
			key: '',
			name: this.constructedOrder.uid + this.constructedOrder.shoes[index].id + 2 + '.png',
			url: '',
			file: new File([], ''),
		}
		this.constructedOrder.shoes.splice(index, 1)
		this.uploadService.deleteFileUpload(fileUpload2)
		this.saveCart()
	}

	setQty(id: string, amount: number) {
		this.loadCart()
		this.constructedOrder.shoes.filter((shoeOrder) => shoeOrder.id == id)[0].amount = amount
		this.saveCart()
	}

	private readonly cartSubject: BehaviorSubject<Order> = new BehaviorSubject<Order>(this.constructedOrder)
	readonly orderCart$: Observable<Order> = this.cartSubject.asObservable().pipe()
}
