import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { NavigationService } from '../../core/services/navigation.service'
import { AuthService } from '../../modules/auth/auth.service'
import { AppUser, AppUserRole } from '../../../../domain/user/app-user.model'
import { Location } from '@angular/common'
import { NavbarService } from './navbar.service'
import { Router } from '@angular/router'
import { mergeMap, take } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { SettingsEditDialog } from 'src/app/shared/settings/settings-edit.dialog'
import { Observable } from 'rxjs'
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'
import { DestroyAware } from '../../shared/utils/destroy-aware.decorator'
import { ShopService } from '../../core/services/shop.service'
import { ShoppingCartDialog } from '../../shared/shopping-cart/shopping-cart.dialog'
import { CartService } from 'src/app/modules/dashboard/shop/services/cart.service'
import { NotificationPanelDialog } from 'src/app/shared/notifications/notification-panel.dialog'
import { NotificationsFirestoreService } from 'src/app/core/firestore-services/notifications-firestore.service'
import { AppUsersFirestoreService } from 'src/app/core/firestore-services/app-users-firestore.service'
import { SnackbarService } from 'src/app/core/services/snackbar.service'

@DestroyAware()
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
	readonly user$: Observable<AppUser | null> = this.authService.appUser$
	readonly logoUrl1$: Observable<string> = this.shopSettingsService.logoUrl1$
	readonly isPublicShop$: Observable<boolean> = this.shopService.isPublicShop$
	readonly notificationsBadge$: Observable<number> = this.firestoreService.getNewNotificationsCount()
	readonly Role = AppUserRole
	searchInput: string = ''
	bSideMenu: boolean = false
	hideNotifications: boolean = false
	hideShoppingCart: boolean = true
	cartElements: number = 0

	constructor(
		@Inject(NotificationsFirestoreService) private readonly firestoreService: NotificationsFirestoreService,
		@Inject(NavigationService) private readonly navigationService: NavigationService,
		@Inject(SnackbarService) private readonly snackbarService: SnackbarService,
		@Inject(AuthService) private readonly authService: AuthService,
		@Inject(Location) private readonly location: Location,
		@Inject(NavbarService) readonly navbarService: NavbarService,
		@Inject(MatDialog) private readonly dialog: MatDialog,
		@Inject(Router) readonly router: Router,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef,
		private readonly shopSettingsService: ShopSettingsService,
		private readonly shopService: ShopService,
		readonly cartService: CartService,
		private readonly appUsersFirestoreService: AppUsersFirestoreService,
	) {
		this.authService.appUserChanges$.subscribe((user) => {
			if (user) {
				;[AppUserRole.Seller].includes(user?.role) ? (this.hideNotifications = true) : null
				user.email === 'demo.customyour.shoes@gmail.com'
					? (this.hideShoppingCart = true)
					: (this.hideShoppingCart = false) // Close your eyes
			}
		})
	}

	ngOnInit(): void {
		this.cartService.orderCart$.subscribe((data) => {
			this.cartElements = data.shoes ? data.shoes.length : 0
			this.cdf.markForCheck()
		})
	}

	search(): void {
		const route = this.router.url.substring(1)
		if (route.includes('shop')) {
			if (this.searchInput && this.searchInput.trim() !== '') {
				this.router.navigate([route], { queryParams: { q: this.searchInput.trim() } })
			} else {
				this.router.navigate([route])
			}
		} else {
			if (this.searchInput && this.searchInput.trim() !== '') {
				this.router.navigate(['/dashboard'], { queryParams: { q: this.searchInput.trim() } })
			} else {
				this.router.navigate(['dashboard'])
			}
		}
	}

	editSettings(): void {
		this.closeMenuMobile()
		this.showSettingsDialog()
	}

	shoppingCart(): void {
		this.closeMenuMobile()
		this.showCartDialog()
	}

	notificationsPanel(): void {
		this.closeMenuMobile()
		this.showNotificationsDialog()
	}

	setSearchInput(event: any): void {
		this.searchInput = event.target.value
	}

	goToFinancialOrders(): void {
		this.navigationService.goToFinancialOrders()
	}

	goToFinancialInvoices(): void {
		this.navigationService.goToFinancialInvoices()
	}

	goToBusinessClientOrders(): void {
		this.navigationService.goToBusinessClientOrders()
	}

	goToBusinessClientSellers(): void {
		this.navigationService.goToBusinessClientSellers()
	}

	goToBusinessClientShoes(): void {
		this.navigationService.goToBusinessClientShoes()
	}

	goToLogin(): void {
		this.navigationService.goToLogin()
	}

	goBack(): void {
		this.location.back()
	}

	login(): void {
		this.goToLogin()
	}

	logout(): void {
		this.closeMenuMobile()
		this.shopSettingsService.currentUserShopSettings$.subscribe((settings) => {
			this.authService.logout(true, settings?.url)
		})
	}

	placeOrder(): void {
		this.navbarService.placeOrderSubject.next()
	}

	closeMenuMobile(): void {
		this.bSideMenu = false
	}

	onLogoClick(): void {
		this.navigationService.goToDashboard().subscribe()
	}

	private showSettingsDialog(): void {
		this.shopSettingsService.currentUserShopSettings$
			.pipe(
				mergeMap((settings) =>
					this.dialog
						.open(SettingsEditDialog, {
							data: settings,
							width: '550px',
							autoFocus: false,
							disableClose: true,
						})
						.afterClosed(),
				),
			)
			.subscribe()
	}

	private showCartDialog(): void {
		this.cartService.orderCart$.pipe(take(1)).subscribe((constructedOrder) => {
			const dialogRef = this.dialog.open(ShoppingCartDialog, {
				data: constructedOrder,
				width: '700px',
				autoFocus: false,
			})
			dialogRef.afterClosed().subscribe((message) => {
				if (message) {
					this.snackbarService.showMessage(message)
				}
			})
			// this.shoeOrders.forEach((shoeOrder) => {
			// 	this.images.push(
			// 		this.storage
			// 			.ref(`uploads/snapshots/${constructedOrder.uid}${shoeOrder.id ? shoeOrder.id : ''}1.png`)
			// 			.getDownloadURL()
			// 	)
			// })
			this.cdf.markForCheck()
		})
	}

	private showNotificationsDialog(): void {
		this.authService.appUser$
			.pipe(
				mergeMap((loggedUser) => {
					const user = { uid: loggedUser?.uid, lastCheck: new Date().toISOString() } as AppUser
					this.appUsersFirestoreService.update(user)
					return this.dialog
						.open(NotificationPanelDialog, {
							width: '600px',
							autoFocus: false,
						})
						.afterClosed()
				}),
			)
			.subscribe()
	}
}
