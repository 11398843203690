import { Component, NgModule } from '@angular/core'
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component'
import { pipe } from 'rxjs'
import {
	AngularFireAuthGuard,
	canActivate,
	customClaims,
	hasCustomClaim,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard'
import { map, tap } from 'rxjs/operators'
import { AuthService } from './modules/auth/auth.service'
import { LayoutComponent } from './layout/layout/layout.component'
import { LogoutGuard } from './logout.guard'

import { GdprComponent } from 'src/app/gdpr/gdpr.component'
import { PageNotFoundComponent } from 'src/app/page-not-found-component'

/** Used only because it's mandatory to have a component when using route guards. Role based navigation is done on it's path */
@Component({ selector: 'app-navigation-placeholder', template: '' })
export class NavigationPlaceholderComponent {}

const redirectLoggedInHome = () => redirectLoggedInTo([''])
const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	pipe(
		redirectUnauthorizedTo(['auth']),
		tap((value) => (AuthService.REDIRECT_AFTER_AUTH_URL = value === true ? null : state.url)),
	)
const adminOnly = () => hasCustomClaim('admin')
const editorOnly = () => hasCustomClaim('editor')
const financialOnly = () => hasCustomClaim('financial')
const businessClientOnly = () => hasCustomClaim('businessClient')
const salesAgentOnly = () => hasCustomClaim('salesAgent')
const demoOnly = () => hasCustomClaim('demo')

const homeRedirect = () =>
	pipe(
		customClaims,
		map((claims) => {
			if ((claims as { salesAgent?: boolean }).salesAgent) {
				return ['sales']
			}
			// if (claims.editor) {
			// 	return ['editor']
			// }
			// if (claims.businessClient) {
			// 	return ['business']
			// }
			return ['dashboard']
		}),
	)

export const appRoutes: Routes = [
	{
		path: 'auth',
		component: AuthLayoutComponent,
		...canActivate(redirectLoggedInHome),
		children: [{ path: '', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) }],
	},
	{
		path: 'gdpr',
		component: GdprComponent,
	},
	{
		path: 'shop',
		component: LayoutComponent,
		children: [
			{
				path: ':shop',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: '',
				pathMatch: 'full',
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: homeRedirect },
				component: NavigationPlaceholderComponent,
			},
		],
	},
	{
		path: '',
		component: LayoutComponent,
		...canActivate(redirectUnauthorizedToLogin),
		children: [
			{
				path: 'admin',
				loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
				...canActivate(adminOnly),
			},
			{
				path: 'editor',
				loadChildren: () => import('./modules/editor/editor.module').then((m) => m.EditorModule),
				...canActivate(editorOnly),
			},
			{
				path: 'business',
				loadChildren: () =>
					import('./modules/business-client/business-client.module').then((m) => m.BusinessClientModule),
				...canActivate(businessClientOnly),
			},
			{
				path: 'sales',
				loadChildren: () => import('./modules/sales/sales-agent.module').then((m) => m.SalesAgentModule),
				...canActivate(salesAgentOnly),
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'demo',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
				...canActivate(demoOnly),
			},
			{
				path: '',
				pathMatch: 'full',
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: homeRedirect },
				component: NavigationPlaceholderComponent,
			},
		],
	},
	{ path: 'logout', canActivate: [LogoutGuard], component: NavigationPlaceholderComponent },
	{ path: '**', component: PageNotFoundComponent },
]

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	declarations: [NavigationPlaceholderComponent],
	exports: [RouterModule],
})
export class AppRoutingModule {}
