import { Directive, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { FirestoreDataSource } from './firestore-data-source'
import { ColActionEvent, ColDef } from '../modules/table/col-def.model'
import { FirestoreFilter } from '../modules/fire/firestore/list/firestore-filter'
import { FirestoreService } from '../modules/fire/firestore.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { SnackbarService } from '../../core/services/snackbar.service'
import { FirestoreCollectionService, FirestoreListService } from '../modules/fire/firestore/list/firestore-list.service'
import { Sort } from '@angular/material/sort'
import { FirestoreSort } from '../modules/fire/firestore/list/firestore-sort'
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators'
import { combineLatest, Observable, Subject } from 'rxjs'
import { ComponentType } from '@angular/cdk/overlay'
import { FirestoreDoc } from '../../../../commons/firestore/firestore-doc.model'
import { FirestorePaginatorComponent } from '../modules/fire/firestore/firestore-paginator.component'
import { AuthService } from '../../modules/auth/auth.service'

@Directive()
export abstract class AbstractTableComponent<T extends FirestoreDoc, A = unknown> implements OnInit, OnDestroy {
	dataSource!: FirestoreDataSource<T>
	abstract readonly columns: Array<ColDef<T>>
	readonly fulltextFilterControl: UntypedFormControl = new UntypedFormControl('')
	readonly defaultFilters: Array<FirestoreFilter> = []
	pageSize: number = 10
	@ViewChild(FirestorePaginatorComponent, { static: true }) protected readonly paginator!: FirestorePaginatorComponent
	protected readonly destroyed$: Subject<void> = new Subject<void>()
	protected readonly fullTextFilter$: Observable<Array<FirestoreFilter>> = this.fulltextFilterControl.valueChanges.pipe(
		startWith(''),
		debounceTime(200),
		distinctUntilChanged(),
		map((value) => [...this.defaultFilters, FirestoreFilter.arrayContains('filterKeywords', value?.toLowerCase())]),
	)

	protected constructor(
		protected readonly firestoreService: FirestoreService<T>,
		protected readonly dialog: MatDialog,
		protected readonly snackbarService: SnackbarService,
		protected readonly authService: AuthService,
	) {}

	ngOnInit(): void {
		const firestoreListService = this.createFirestoreListService(this.firestoreService)
		this.dataSource = new FirestoreDataSource<T>(firestoreListService)
		this.observeFilteringAndSorting()
	}

	ngOnDestroy(): void {
		this.destroyed$.next()
	}

	onSort(event: Sort): void {
		if (!event.direction) {
			this.sortSubject.next(undefined)
			return
		}
		if (event.direction === 'asc') {
			this.sortSubject.next(FirestoreSort.ascending(event.active))
		} else {
			this.sortSubject.next(FirestoreSort.descending(event.active))
		}
	}

	onTableAction(event: ColActionEvent<T, unknown>): void {
		if (event.colDef.property === 'actions') {
			const actionsEvent = event as ColActionEvent<T, A>
			this.onActionColumn(actionsEvent)
		}
	}

	protected createFirestoreListService(firestoreService: FirestoreService<T>): FirestoreListService<T> {
		return new FirestoreCollectionService(firestoreService, this.paginator)
	}

	protected onActionColumn(event: ColActionEvent<T, A>): void {}

	protected openDialog<D, O>(type: ComponentType<D> | TemplateRef<D>, data?: O, width?: string): MatDialogRef<D> {
		return this.dialog.open(type, { width: width ?? '600px', data })
	}

	protected openDialogAndDisplayClosingMessage<D, O>(
		type: ComponentType<D> | TemplateRef<D>,
		data?: O,
		width?: string,
	): void {
		const dialogRef = this.openDialog(type, data, width)
		dialogRef
			.afterClosed()
			.subscribe((message: string | undefined) => message && this.snackbarService.showMessage(message))
	}

	protected filters$(): Observable<Array<FirestoreFilter>> {
		return this.fullTextFilter$
	}

	protected observeFilteringAndSorting(): void {
		combineLatest([this.filters$(), this.sortSubject.asObservable().pipe(startWith(undefined))])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([filters, sort]) => this.dataSource.loadData({ filters, sort }))
	}

	private readonly sortSubject: Subject<FirestoreSort | undefined> = new Subject<FirestoreSort | undefined>()
}
