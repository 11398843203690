import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormControl, FormGroup, NgControl, Validators } from '@angular/forms'
import { Order, ShoeOrder } from '../../../../domain/order.model'
import { FormUtils } from '../modules/form/core/form.utils'
import { EnumUtils } from '../utils/enum.utils'
import { ErrorMessageResolver } from '../modules/form/errors/error-message-resolver'
import { from, Observable } from 'rxjs'
import { MatDialog, matDialogAnimations } from '@angular/material/dialog'
import { ConfirmationDialog } from '../dialog/confirmation.dialog'
import { CartService } from 'src/app/modules/dashboard/shop/services/cart.service'
import { ShopSettings } from '../../../../domain/user/shop-settings.model'
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'
import { AppUserCurrency } from '../../../../domain/price-categories'
import { map } from 'rxjs/operators'
import { ControlMultiplierComponent } from '../modules/form/control-multiplier/control-multiplier.component'
import { ControlValueAccessor } from '../modules/form/core/control-value-accessor'

@Component({
	selector: 'app-shoe-orders-form',
	template: `
		<app-control-multiplier [formControl]="control">
			<ng-template appControlTemplate let-ctrl>
				<app-shoe-order-form
					[formControl]="ctrl"
					[currency]="currency"
					(objectRemoved)="removeControl($event)"
				></app-shoe-order-form>
			</ng-template>
		</app-control-multiplier>
	`,
	styleUrls: ['./shoe-orders-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoeOrdersFormComponent extends ControlValueAccessor<Array<ShoeOrder>> implements OnInit {
	shoeOrders: Array<ShoeOrder> = []
	readonly control: UntypedFormControl = new UntypedFormControl([], Validators.required)
	orderSummary: string = ''
	currency: Observable<AppUserCurrency | undefined> = this.shopSettingsService.shopSettings$.pipe(
		map((settings) => settings?.currency),
	)

	constructor(
		@Inject(NgControl) protected readonly ngControl: NgControl,
		@Inject(CartService) private readonly cartService: CartService,
		@Inject(ShopSettingsService) private readonly shopSettingsService: ShopSettingsService,
	) {
		super(ngControl)
	}

	@Input() set shoes(value: Array<ShoeOrder>) {
		this.shoeOrders = value
		if (value) {
			value.forEach((shoeOrder) => {
				this.controlMultiplier.add(shoeOrder)
			})
		}
	}

	get agreedControl(): AbstractControl {
		return this.control.get('agreed') as AbstractControl
	}

	protected getErrorMessage(err: unknown, model: ShoeOrder): string {
		//this.loading = false
		return `Comanda nu a putut fi plasata`
	}

	ngOnInit(): void {
		super.ngOnInit()
	}

	writeValue(value: Array<ShoeOrder>): void {
		super.writeValue(value)
	}

	removeControl(shoeOrderId: string) {
		this.controlMultiplier.removeBy((shoeOrder: any) => shoeOrder.id === shoeOrderId)
	}

	//TODO: translate prompt

	@ViewChild(ControlMultiplierComponent, { static: true })
	private readonly controlMultiplier!: ControlMultiplierComponent<ShoeOrder>
}
