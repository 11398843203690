import { Inject, Injectable } from '@angular/core'
import { FirestoreService } from '../../shared/modules/fire/firestore.service'
import { AppUser } from '../../../../domain/user/app-user.model'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Injectable({ providedIn: 'root' })
export class AppUsersFirestoreService extends FirestoreService<AppUser> {
	constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) {
		super(firestore)
	}

	protected resolvePath(): string {
		return 'users'
	}
}
