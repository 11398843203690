export const environment = {
	production: true,
	firebase: {
		// // APS-DEV (PROD)
		apiKey: 'AIzaSyDeg42sJ27XuCmxDL2eXJk864B-MNgKLGM',
		authDomain: 'aps-dev-39dd8.firebaseapp.com',
		databaseURL: 'https://aps-dev-39dd8.firebaseio.com',
		projectId: 'aps-dev-39dd8',
		storageBucket: 'aps-dev-39dd8.appspot.com',
		messagingSenderId: '497439801196',
		appId: '1:497439801196:web:72458d3b7b37b21515bd49',
		measurementId: 'G-9MT0L9L06R',

		// // CYS-DEV (DEV)
		// apiKey: 'AIzaSyCM0D_96UGtsO6ZTfDKBqhjAbS3baHKCEg',
		// authDomain: 'cys-dev.firebaseapp.com',
		// databaseURL: 'https://cys-dev.firebaseio.com',
		// projectId: 'cys-dev',
		// storageBucket: 'cys-dev.appspot.com',
		// messagingSenderId: '32443473390',
		// appId: '1:32443473390:web:babd9cd43a213fce4cc2ff',
		// measurementId: 'G-KQC979X474',
	},
}
