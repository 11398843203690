import { Directive, TemplateRef } from '@angular/core'

interface Context<T extends object> {
	$implicit: T
}

@Directive({ selector: '[appTableExpandableContentTemplate]' })
export class TableExpandableContentTemplateDirective<T extends object> {
	constructor(readonly templateRef: TemplateRef<T>) {}

	static ngTemplateContextGuard<T extends object>(
		dir: TableExpandableContentTemplateDirective<T>,
		ctx: unknown,
	): ctx is Context<T> {
		return true
	}
}
