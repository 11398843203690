import { Query } from '@angular/fire/compat/firestore'

const enum FirestoreFilterOperator {
	LESS_THAN = '<',
	LESS_THAN_EQ = '<=',
	EQUALS = '==',
	GREATER_THAN = '>',
	GREATER_THAN_EQ = '>=',
	ARRAY_CONTAINS = 'array-contains',
	ARRAY_CONTAINS_ANY = 'array-contains-any',
	IN = 'in',
}

export class FirestoreFilter {
	private constructor(
		readonly property: string,
		readonly operator: FirestoreFilterOperator,
		readonly value: unknown,
		readonly remove: boolean = false
	) {}

	static lessThan(property: string, value: unknown): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.LESS_THAN, value)
	}

	static lessThanOrEquals(property: string, value: unknown): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.LESS_THAN_EQ, value)
	}

	static equals(property: string, value: unknown): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.EQUALS, value)
	}

	static removeEquals(property: string): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.EQUALS, null, true)
	}

	static greaterThan(property: string, value: unknown): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.GREATER_THAN, value)
	}

	static greaterThanOrEquals(property: string, value: unknown): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.GREATER_THAN_EQ, value)
	}

	static arrayContains(property: string, value: string | number): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.ARRAY_CONTAINS, value)
	}

	static arrayContainsAny(property: string, items: Array<unknown>): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.ARRAY_CONTAINS_ANY, items)
	}

	static in(property: string, items: Array<unknown>): FirestoreFilter {
		return new FirestoreFilter(property, FirestoreFilterOperator.IN, items)
	}

	appendTo(query: Query): Query {
		return query.where(this.property, this.operator, this.value)
	}
}

export class FiltersSet {
	constructor(filters: Array<FirestoreFilter> = []) {
		filters.forEach((filter) => this.add(filter))
	}

	add(filter: FirestoreFilter): void {
		const storageKey: string = FiltersSet.getStorageKey(filter)
		this.filters.set(storageKey, filter)
	}

	toArray(): Array<FirestoreFilter> {
		return Array.from(this.filters.values())
	}

	private static getStorageKey(filter: FirestoreFilter): string {
		return `${filter.property}_${filter.operator}`
	}

	private readonly filters: Map<string, FirestoreFilter> = new Map<string, FirestoreFilter>()
}
