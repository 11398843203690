import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { forkJoin, from, Observable, of } from 'rxjs'
import { AbstractFormDialog } from '../dialog/abstract-form-dialog'
import { LoadingService } from '../../core/services/loading.service'
import { Order, OrderStatus, ShoeComponentTableItem, ShoeComponentTableRow, ShoeOrder } from 'domain/order.model'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { CartService } from 'src/app/modules/dashboard/shop/services/cart.service'
import { bufferCount, delay, map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { StripeService } from 'src/app/modules/dashboard/shop/services/stripe.service'
import { ShopService } from 'src/app/core/services/shop.service'
import { ShopSettingsService } from 'src/app/modules/auth/shop-settings.service'
import { OrderPathParams, OrdersFirestoreService } from 'src/app/core/firestore-services/orders-firestore.service'
import { FirestoreService } from '../modules/fire/firestore.service'
import { OrderSellerConfirmDialog } from 'src/app/modules/dashboard/configurator/order/order-seller-confirm/order-seller-confirm.dialog'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { AppUser } from 'domain/user/app-user.model'
import { TranslateService } from '@ngx-translate/core'
import { Shoe, ShoeComponent } from '../../../../domain/shoe.model'
import { ShopSettings } from '../../../../domain/user/shop-settings.model'
import { OrderUtils } from '../../../../commons/utils/order.utils'

@Component({
	selector: 'app-shopping-cart-dialog',
	template: ` <div fxLayout="row wrap" fxLayoutAlign="space-between center">
		<div fxLayout="row nowrap" fxLayoutAlign="space-between center" style="margin-bottom: 0.5rem; width: 100%">
			<h2 mat-dialog-title style="margin: 0">{{ 'obiect.shoppingCart' | translate }}</h2>
			<button
				mat-dialog-close
				mat-button
				class="dialog-exit-button"
				(click)="close()"
				style="color:rgba(0, 0, 0, 0.25); min-width: 2rem !important; padding: 0 !important"
			>
				<mat-icon>cancel</mat-icon>
			</button>
		</div>
		<form [formGroup]="form" (fmxSubmit)="onSubmit()" fxFlex="100">
			<mat-dialog-content>
				<app-cart-order-form
					*ngIf="shoeOrders.length"
					formControlName="model"
					(agreed)="agreed = $event"
					[isPublicShop]="!!(this.shopService.isPublicShop$ | async)"
				>
				</app-cart-order-form>
				<mat-error *ngIf="errors$ | async as errors" class="mb-3">{{ errors }}</mat-error>
				<p *ngIf="!shoeOrders.length">{{ 'dialog.emptyCart' | translate }}</p>
			</mat-dialog-content>
			<div mat-dialog-actions>
				<button mat-button mat-dialog-close class="btn-custom-default" (click)="close()">
					{{ 'actiune.inchide' | translate }}
				</button>
				<button
					mat-raised-button
					*ngIf="shoeOrders.length"
					type="submit"
					[ngClass]="agreed ? 'btn-custom-primary' : 'btn-custom-primary-disabled'"
					color="primary"
					[disabled]="!agreed"
				>
					<ng-container>{{ 'actiune.comanda' | translate }}</ng-container>
					<mat-icon>arrow_right_alt</mat-icon>
				</button>
			</div>
		</form>
	</div>`,
	//styleUrls: ['./shoe-orders-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingCartDialog extends AbstractFormDialog<Order, ShoppingCartDialog> {
	order_seller: any = {}
	agreed: boolean = false

	images: Array<Observable<string>> = []

	constructor(
		@Inject(MatDialogRef) protected readonly dialogRef: MatDialogRef<ShoppingCartDialog>,
		@Inject(AngularFireStorage) private readonly storage: AngularFireStorage,
		@Inject(CartService) private readonly cartService: CartService,
		@Inject(LoadingService) readonly loadingService: LoadingService,
		@Inject(StripeService) readonly stripeService: StripeService,
		@Inject(ShopService) readonly shopService: ShopService,
		@Inject(AuthService) protected readonly authService: AuthService,
		private readonly shopSettingsService: ShopSettingsService,
		@Inject(MatDialog) private readonly dialog: MatDialog,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef,
		@Inject(OrdersFirestoreService) private readonly ordersService: FirestoreService<Order, OrderPathParams>,
		@Inject(MAT_DIALOG_DATA) private readonly dialogData: Order,
		@Inject(TranslateService) private readonly translateService: TranslateService,
	) {
		super(dialogRef, loadingService)
	}

	get shoeOrders(): Array<ShoeOrder> {
		return this.dialogData.shoes
	}

	onSubmit(): void {
		this.shopService.isPublicShop$.subscribe((isPublicShop) =>
			isPublicShop ? super.onSubmit() : this.showOrderSellerConfirmDialog(),
		)
	}

	close(): void {}

	protected initialFormValue(): Order {
		return this.dialogData
	}

	/**
	 * When an order is submitted we need data from the active user, the current shop and the cart.
	 * In order to send correct emails, we translate the shoe components to the current language (on FE).
	 * Having modified the order, we initiate checkout depending on the shop type.
	 *
	 * Currently a mess, could use some @refactoring.
	 * @protected
	 */
	protected action$(order: Order): Observable<unknown> {
		let publicShop = false
		let shopSettings: ShopSettings
		order.seller = this.order_seller
		return forkJoin([
			this.authService.appUser$ as Observable<AppUser>,
			this.shopService.isPublicShop$,
			this.shopSettingsService.shopSettings$,
			this.cartService.orderCart$.pipe(take(1)),
			this.cartService.getOrderNumbers().pipe(take(1)),
		]).pipe(
			map(([appUser, isPublicShop, settings, constructedOrder, orderNumbers]) => {
				publicShop = isPublicShop
				shopSettings = settings
				order.amount = orderNumbers.totalShoes
				order.value_sell = orderNumbers.totalSellValue
				order.value = orderNumbers.totalValue
				order.pay_seller = appUser ? AppUser.toUserVO(appUser) : undefined
				order.lang = this.translateService.currentLang
				order.uid = constructedOrder.uid
				order.shoes = constructedOrder.shoes
				order.notes = ''
				let summaries: Record<string, { name: string; summary: ShoeComponentTableItem[] }> = {}
				order.shoes.forEach((shoeOrder) => {
					if (shoeOrder.description) {
						order.notes += shoeOrder.shoe.name + ' - ' + shoeOrder.description + ' '
					}
					summaries[shoeOrder.id] = {
						name: shoeOrder.shoe.displayName,
						summary: OrderUtils.computeSummary(shoeOrder.shoe),
					}
				})
				return summaries
			}),
			switchMap((summaries) => {
				// initiate translations and wait for them to finish
				let summaryTranslations: Array<Observable<any>> = []
				Object.keys(summaries).forEach((shoeOrderId) => {
					summaryTranslations.push(
						this.generateShoeOrderText(
							summaries[shoeOrderId].name,
							order.uid,
							shoeOrderId,
							summaries[shoeOrderId].summary,
						),
					)
				})
				return forkJoin(summaryTranslations)
			}),
			switchMap((translationArray) => {
				// set translations and switch to check out observable
				order.shoes.forEach((shoeOrder) => {
					let index = translationArray.findIndex((t) => t.shoeIdentifier === shoeOrder.id)
					shoeOrder.shoeName = translationArray[index].shoeName
					shoeOrder.imageUrl = translationArray[index].imageUrl
					shoeOrder.componentTableRows = translationArray[index].translations
				})
				this.stripConfiguratorPropertiesFromOrder(order)
				if (publicShop) {
					return this.stripeService
						.checkout(order)
						.pipe(mergeMap((res) => this.stripeService.redirectToCheckout(res.id)))
				} else {
					order.status = OrderStatus.New
					return from(this.ordersService.add(order, { userUid: shopSettings.uid })).pipe(
						tap(() => this.cartService.clearCart()),
					)
				}
			}),
		)
	}

	protected getErrorMessage(err: unknown, model: Order): string {
		return `Error!`
	}

	protected getSuccessMessage(model: Order): string {
		return `Success!`
	}

	protected getModelToSave(): Order {
		const { agreed, ...model } = this.model as Order & { agreed: boolean }
		return { ...model, status: OrderStatus.Draft, shoes: this.shoeOrders, pay_seller: {} } as Order
	}

	private stripConfiguratorPropertiesFromOrder(order: Order) {
		for (const shoeOrder of order.shoes) {
			for (const shoeComponent of shoeOrder.shoe.components) {
				shoeComponent.materialOptions = []
				shoeComponent.modelOptions = []
				shoeComponent.material && (shoeComponent.material.colorOptions = [])
			}
		}
	}

	private showOrderSellerConfirmDialog(): void {
		const dialogRef = this.dialog.open(OrderSellerConfirmDialog, {
			width: '500px',
			disableClose: true,
		})
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((data) => {
				if (data && data?.status === 'success') {
					this.order_seller = { uid: data.seller.uid, name: data.seller.name }
					super.onSubmit()
					// this.cdf.markForCheck()
				}
			})
	}

	private generateShoeOrderText(
		shoeDisplayName: string,
		orderID: string,
		shoeID: string,
		shoeComponentsSummary: Array<ShoeComponentTableItem>,
	): Observable<any> {
		let shoeName = ''
		return from(shoeComponentsSummary).pipe(
			switchMap((item) => {
				return forkJoin([of(item), this.translateService.get(shoeDisplayName)])
			}),
			switchMap(([item, translatedName]) => {
				shoeName = translatedName
				const translationsToFetch = []
				translationsToFetch.push(item.name)
				if (item.modelOption) {
					translationsToFetch.push(item.modelOption)
					item.material && translationsToFetch.push(item.material)
				} else {
					translationsToFetch.push(item.material)
					item.patina && translationsToFetch.push('material.patinat')
				}
				translationsToFetch.push(item.color)
				return this.translateService.get(translationsToFetch)
			}),
			map((translations: Record<string, string>) => {
				const translationValues = Object.values(translations).map((translation) => translation ?? '')
				const tableRow: ShoeComponentTableRow = {
					col1: '',
					col2: '',
					col3: '',
				}
				if (translationValues.length === 4) {
					tableRow.col1 = translationValues[0] ?? ''
					tableRow.col2 = translationValues[1] + ' - ' + translationValues[2] ?? ''
					tableRow.col3 = translationValues[3] ?? ''
				} else if (translationValues.length === 3) {
					tableRow.col1 = translationValues[0] ?? ''
					tableRow.col2 = translationValues[1] ?? ''
					tableRow.col3 = translationValues[2] ?? ''
				}
				return tableRow
			}),
			bufferCount(shoeComponentsSummary.length),
			switchMap((translations) => {
				// @refactor - this shouldn't be here
				return forkJoin([
					of(translations),
					this.storage.ref(`uploads/snapshots/${orderID}${shoeID ?? ''}1.png`).getDownloadURL(),
				])
			}),
			map(([translations, imageUrl]) => {
				return {
					shoeIdentifier: shoeID,
					shoeName: shoeName,
					imageUrl: imageUrl,
					translations: translations,
				}
			}),
		)
	}
}
