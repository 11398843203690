import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputComponent } from './input.component'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { NumberInputComponent } from './number-input.component'
import { TextAreaInputComponent } from './textarea-input.component'
import { FmxErrorsModule } from '../errors/errors.module'

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatInputModule, FmxErrorsModule, MatIconModule, MatButtonModule],
	declarations: [InputComponent, NumberInputComponent, TextAreaInputComponent],
	exports: [InputComponent, NumberInputComponent, TextAreaInputComponent],
})
export class InputModule {}
