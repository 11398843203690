import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface ConfirmationDialogData {
	title: string
	question: string
	confirmLabel?: string
	declineLabel?: string
}

@Component({
	selector: 'app-confirmation-dialog',
	template: `
		<h2 matDialogTitle>{{ data.title }}</h2>

		<mat-dialog-content>
			<p style="white-space: pre-wrap">{{ data.question }}</p>
		</mat-dialog-content>

		<div mat-dialog-actions>
			<button mat-flat-button color="accent" class="btn-custom-default" [matDialogClose]="false">
				{{ data.declineLabel || 'Nu' }}
			</button>
			<button mat-flat-button color="warn" class="btn-custom-primary" [matDialogClose]="true">
				{{ data.confirmLabel || 'Da' }}
			</button>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialog {
	constructor(@Inject(MAT_DIALOG_DATA) readonly data: ConfirmationDialogData) {}
}
