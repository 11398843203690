import {
	AngularFirestore,
	AngularFirestoreCollection,
	AngularFirestoreCollectionGroup,
	AngularFirestoreDocument,
	QueryFn,
	QueryGroupFn,
	SetOptions,
} from '@angular/fire/compat/firestore'
import { Observable, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { FirestoreDoc } from '../../../../../commons/firestore/firestore-doc.model'

export type FirestorePathParams = { [s: string]: string }

export abstract class FirestoreService<T extends FirestoreDoc, PP extends FirestorePathParams = never> {
	protected constructor(protected readonly firestore: AngularFirestore) {}

	protected get collectionGroupPath(): string | undefined {
		return undefined
	}

	generateUid(): string {
		return this.firestore.createId()
	}

	read(queryFn?: QueryFn, pathParams?: PP): AngularFirestoreCollection<T> {
		return this.firestore.collection<T>(this.resolvePath(pathParams), queryFn)
	}

	readValue(queryFn?: QueryFn, pathParams?: PP): Observable<Array<T>> {
		return this.read(queryFn, pathParams).valueChanges().pipe(take(1))
	}

	readGroup(queryGroupFn?: QueryGroupFn<T>): AngularFirestoreCollectionGroup<T> {
		if (!this.collectionGroupPath) {
			throw new Error('Collection group path is not defined')
		}
		return this.firestore.collectionGroup<T>(this.collectionGroupPath, queryGroupFn)
	}

	get(uid: string, pathParams?: PP): AngularFirestoreDocument<T> {
		return this.firestore.doc<T>(`${this.resolvePath(pathParams)}/${uid}`)
	}

	getValue(uid: string, pathParams?: PP): Observable<T | null> {
		return this.observeValues(uid, pathParams).pipe(take(1))
	}

	observeValues(uid: string, pathParams?: PP): Observable<T | null> {
		if (!uid) {
			return of(null)
		}
		return this.get(uid, pathParams)
			.valueChanges()
			.pipe(map((doc) => doc ?? null))
	}

	add(doc: T, pathParams?: PP): Promise<void | null> {
		if (!doc) return of(null).toPromise()

		if (!doc.uid) {
			doc.uid = this.generateUid()
		}

		doc = this.preSave(doc)
		return this.read(undefined, pathParams)
			.doc(doc.uid)
			.set({ ...doc })
	}

	set(doc: T, options?: SetOptions, pathParams?: PP): Promise<void | null> {
		if (!doc) return of(null).toPromise()

		this.checkIfDocHasUid(doc)
		doc = this.preSave(doc)
		return this.get(doc.uid as string, pathParams).set({ ...doc }, options)
	}

	update(doc: T, pathParams?: PP): Promise<void | null> {
		if (!doc) {
			return of(null).toPromise()
		}

		this.checkIfDocHasUid(doc)
		doc = this.preUpdate(doc)
		return this.get(doc.uid as string, pathParams).set({ ...doc }, { merge: true })
	}

	delete(uid: string, pathParams?: PP): Promise<void> {
		return this.get(uid, pathParams).delete()
	}

	protected abstract resolvePath(params?: PP): string

	protected preSave(doc: T): T {
		// @ts-expect-error need this to preserve functionality, this will be populated by the onWrite fn
		delete doc.updatedAt
		// @ts-expect-error need this to preserve functionality, this will be populated by the onWrite fn
		delete doc.createdAt
		return doc
	}

	protected preUpdate(doc: T): T {
		return this.preSave(doc)
	}

	private checkIfDocHasUid(doc: T): void {
		if (!doc.uid) {
			throw new Error(`Missing or empty value for 'uid' property of document: ${JSON.stringify(doc)}`)
		}
	}
}
