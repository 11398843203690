import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component'
import { LayoutComponent } from './layout/layout/layout.component'
import { MatCardModule } from '@angular/material/card'
import { CoreModule } from './core/core.module'
import { NavbarComponent } from './layout/navbar/navbar.component'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { SharedModule } from './shared/shared.module'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatIconModule } from '@angular/material/icon'
import { SettingsModule } from 'src/app/shared/settings/settings.module'
import { GdprComponent } from 'src/app/gdpr/gdpr.component'
import { PageNotFoundComponent } from 'src/app/page-not-found-component'

import 'hammerjs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { UserTermsModule } from './modules/user-terms/user-terms.module'
import { MatDialogModule } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { MatBadgeModule } from '@angular/material/badge'
import { CartService } from './modules/dashboard/shop/services/cart.service'
import { LanguageSwitchComponent } from './layout/navbar/language-switch/language-switch.component'

const MATERIAL_MODULES = [
	MatCardModule,
	MatToolbarModule,
	MatButtonModule,
	MatProgressBarModule,
	MatIconModule,
	MatTooltipModule,
	MatBadgeModule,
	MatDialogModule,
]

@NgModule({
	declarations: [
		AppComponent,
		AuthLayoutComponent,
		LayoutComponent,
		LanguageSwitchComponent,
		NavbarComponent,
		GdprComponent,
		PageNotFoundComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		HammerModule,
		...MATERIAL_MODULES,
		SettingsModule,
		UserTermsModule,
		TranslateModule,
	],
	providers: [CartService],
	bootstrap: [AppComponent],
})
export class AppModule {}
