import { Directive, Input, OnDestroy } from '@angular/core'
import { ColActionEvent, ColDef } from '../col-def.model'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export type ColumnData<T extends object, Type> = Type extends AbstractColumnComponent<T, infer D> ? D : never

@Directive()
// tslint:disable-next-line:directive-class-suffix no-any
export abstract class AbstractColumnComponent<T extends object, D = unknown, A = any> implements OnDestroy {
	@Input() def!: ColDef<T>
	@Input() row!: T

	get value(): any | null {
		if (!(this.row && this.def?.property in this.row)) {
			return null
		}
		return this.row[this.def?.property as keyof T]
	}

	get data(): D {
		return this.def?.data as D
	}

	ngOnDestroy(): void {
		this.destroyed$.next()
	}

	emitAction(value: A): void {
		const event: ColActionEvent<T, A> = { colDef: this.def, row: this.row, value }
		this.actionSubject.next(event)
	}

	private readonly destroyed$: Subject<void> = new Subject<void>()
	private readonly actionSubject: Subject<ColActionEvent<T, A>> = new Subject<ColActionEvent<T, A>>()
	// tslint:disable-next-line:member-ordering
	readonly action$: Observable<ColActionEvent<T, A>> = this.actionSubject
		.asObservable()
		.pipe(takeUntil(this.destroyed$))
}

export function TableColumn<T extends object, C extends AbstractColumnComponent<T>>(def: ColDef<T, C>): ColDef<T, C> {
	return def as ColDef<T, C>
}
