import { Inject, Injectable } from '@angular/core'
import { from, Observable, of } from 'rxjs'
import { AppUser } from '../../../../domain/user/app-user.model'
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators'
import { FirestoreService } from '../../shared/modules/fire/firestore.service'
import { AppUsersFirestoreService } from '../../core/firestore-services/app-users-firestore.service'
import { NavigationService } from '../../core/services/navigation.service'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { DestroyAware } from '../../shared/utils/destroy-aware.decorator'
import firebase from 'firebase/compat'

@DestroyAware()
@Injectable({ providedIn: 'root' })
export class AuthService {
	static REDIRECT_AFTER_AUTH_URL: string | null = null
	userUid: string | null = null
	readonly appUserChanges$: Observable<AppUser | null> = this.auth.authState.pipe(
		distinctUntilChanged(),
		tap((user) => (this.userUid = user?.uid ?? null)),
		switchMap((user) => (user ? this.findAppUser(user) : of(null))),
		shareReplay(1)
	)
	readonly appUser$: Observable<AppUser | null> = this.appUserChanges$.pipe(take(1))

	constructor(
		@Inject(AngularFireAuth) private readonly auth: AngularFireAuth,
		@Inject(AppUsersFirestoreService) private readonly userFirestoreService: FirestoreService<AppUser>,
		@Inject(NavigationService) private readonly navigationService: NavigationService
	) {}

	signInWithEmailAndPassword(email: string, password: string): Observable<unknown> {
		return from(this.auth.signInWithEmailAndPassword(email, password))
	}

	logout(redirectToSignIn: boolean = true, url: string = ''): Promise<unknown> {
		return this.auth.signOut().then(() => {
			this.userUid = null
			if (redirectToSignIn) {
				return this.navigationService.goToLogin(url)
			}
			return null
		})
	}

	hasAnyClaim(claims: Array<string>): Observable<boolean> {
		return this.auth.idTokenResult.pipe(
			map((tokenResult) => (tokenResult ? this.isMatchingAnyClaim(tokenResult.claims, claims) : false))
		)
	}

	private findAppUser(user: firebase.User): Observable<AppUser | null> {
		return this.userFirestoreService
			.get(user.uid)
			.valueChanges()
			.pipe(
				map((value) => value as AppUser),
				catchError(() => of(null))
			)
	}

	private isMatchingAnyClaim(userClaims: { [key: string]: unknown }, claimsToMatch: Array<string>): boolean {
		return Object.keys(userClaims).some((claim) => claimsToMatch.includes(claim))
	}
}
