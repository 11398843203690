import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
	selector: 'app-layout',
	template: `
		<app-navbar></app-navbar>
		<main class="p-3">
			<router-outlet></router-outlet>
		</main>
	`,
	styleUrls: ['./layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {}
