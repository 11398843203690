import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { ConfiguratorComponentQueryParams } from '../../modules/dashboard/configurator/configurator.model'
import { forkJoin, Observable } from 'rxjs'
import { ShopService } from './shop.service'
import { map, mergeMap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class NavigationService {
	constructor(private readonly router: Router, private readonly shopService: ShopService) {}

	goTo(path: string, extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigate([path], extras)
	}

	goToHome(): Promise<boolean> {
		return this.goTo('')
	}

	goToLogin(url: string = ''): Promise<boolean> {
		return url === '' ? this.goTo('auth') : this.goTo('auth', { queryParams: { url } })
	}

	goToAdminUsers(): Promise<boolean> {
		return this.goTo('admin/users')
	}

	goToAdminOrders(): Promise<boolean> {
		return this.goTo('admin/orders')
	}

	goToAdminInvoices(): Promise<boolean> {
		return this.goTo('admin/invoices')
	}

	goToAdminShoes(): Promise<boolean> {
		return this.goTo('admin/shoes')
	}

	goToFinancialOrders(): Promise<boolean> {
		return this.goTo('financial/orders')
	}

	goToFinancialInvoices(): Promise<boolean> {
		return this.goTo('financial/invoices')
	}

	goToEditorShoes(): Promise<boolean> {
		return this.goTo('editor/shoes')
	}

	goToBusinessClientOrders(): Promise<boolean> {
		return this.goTo('business/orders')
	}

	goToBusinessClientSellers(): Promise<boolean> {
		return this.goTo('business/sellers')
	}

	goToBusinessClientShoes(): Promise<boolean> {
		return this.goTo('business/shoes')
	}

	goToDashboard(): Observable<boolean> {
		return this.getShopBaseRoute().pipe(mergeMap((route) => this.goTo(route)))
	}

	goToShoeConfigurator(queryParams: ConfiguratorComponentQueryParams): Observable<boolean> {
		return this.getShopBaseRoute().pipe(mergeMap((route) => this.goTo(`${route}/configurator`, { queryParams })))
	}

	goToPageNotFound(): Promise<boolean> {
		return this.goTo('404')
	}

	private getShopBaseRoute(): Observable<string> {
		return forkJoin([this.shopService.isPublicShop$, this.shopService.publicShopUrl$]).pipe(
			map(([isPublicShop, publicShopUrl]) =>
				isPublicShop ? `${ShopService.PUBLIC_SHOP_URL_BASE_PATH}/${publicShopUrl}` : 'dashboard'
			)
		)
	}
}
