import { Inject, Injectable } from '@angular/core'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { from, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { ShopSettingsService } from '../../../auth/shop-settings.service'
import { ShopService } from '../../../../core/services/shop.service'
import { Order } from '../../../../../../domain/order.model'
import { PixelService } from '@bloomscorp/ngx-pixel'
import { OrderUtils } from '../../../../../../commons/utils/order.utils'
import { OrderUtilsService } from '../../../../shared-business-logic/order/order-utils.service'

@Injectable({
	providedIn: 'root',
})
export class StripeService {
	stripe$: Observable<Stripe | null> = this.shopSettingsService.shopSettings$.pipe(
		mergeMap((settings) => from(loadStripe(settings.stripeApiKey))),
		shareReplay(1),
	)

	constructor(
		@Inject(AngularFireFunctions) private readonly fireFunctions: AngularFireFunctions,
		private readonly shopSettingsService: ShopSettingsService,
		private readonly shopService: ShopService,
		private readonly orderUtilsService: OrderUtilsService,
		private readonly pixel: PixelService,
	) {}

	checkout(order: Order): Observable<any> {
		const stripeCheckoutFn = this.fireFunctions.httpsCallable('stripeCheckout')
		return this.shopSettingsService.shopSettings$.pipe(
			mergeMap((settings) =>
				stripeCheckoutFn({ shopUid: settings.uid, order }).pipe(
					take(1),
					mergeMap((res) => this.trackCheckout(order).pipe(map(() => res))),
				),
			),
		)
	}

	redirectToCheckout(sessionId: string): Observable<never | { error: any }> {
		return this.stripe$.pipe(
			mergeMap((stripe: Stripe | null) => {
				if (stripe) {
					return from(stripe.redirectToCheckout({ sessionId }))
				}
				return of({ error: "Stripe wasn't loaded" })
			}),
		)
	}

	private trackCheckout(order: Order): Observable<unknown> {
		const summary = OrderUtils.toOrderPixelSummary(order)
		return this.orderUtilsService.translateOrderPixelSummary(summary).pipe(
			map((_summary) => this.orderUtilsService.mapToPixelEventProperties(_summary)),
			tap((properties) => this.pixel.track('InitiateCheckout', properties)),
			catchError(() => of(null)),
		)
	}
}
