import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core'
import { MaterialControlValueAccessor } from '../core/material-cva'
import { UntypedFormControl, NgControl } from '@angular/forms'

@Component({
	selector: 'app-input-color',
	template: `
		<p style="margin-bottom: 0" *ngIf="label">{{ label }}</p>
		<mat-form-field
			[appearance]="appearance"
			class="w-100"
			fxLayout="row nowrap"
			[class.color-picker-open]="colorPickerOpen"
		>
			<div fxFlex="10" class="color-preview-circle" [style.background-color]="color_prop"></div>
			<input
				fxFlex="70"
				style="padding-left: 15px"
				matInput
				type="text"
				[(colorPicker)]="color_prop"
				placeholder="{{ placeholder }}"
				[formControl]="control"
				[errorStateMatcher]="errorStateMatcher"
				(colorPickerClose)="colorPickerOpen = false"
				(colorPickerOpen)="colorPickerOpen = true"
			/>
			<mat-error>
				<fmx-errors [control]="externalControl" [controlLabel]="errorLabel || label || placeholder"></fmx-errors>
			</mat-error>
		</mat-form-field>
	`,
	styleUrls: ['input-color.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputColorComponent extends MaterialControlValueAccessor {
	@Input() color: string = '#f2f2f2'
	@Output() readonly iconClick: EventEmitter<void> = new EventEmitter<void>()
	readonly control: UntypedFormControl = new UntypedFormControl()

	colorPickerOpen: boolean = false

	constructor(@Inject(NgControl) protected readonly controlDirective: NgControl) {
		super(controlDirective)
	}

	public get color_prop(): string {
		return this.control.value
	}
	public set color_prop(value: string) {
		this.control.setValue(value)
	}
}
