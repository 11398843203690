import { CollectionViewer } from '@angular/cdk/collections'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { catchError, shareReplay, takeUntil } from 'rxjs/operators'
import { TableDataSource } from '../modules/table/table-data-source'
import { FirestoreRequest } from '../modules/fire/firestore/list/firestore-request'
import { FirestoreListService } from '../modules/fire/firestore/list/firestore-list.service'
import { FirestoreDoc } from '../../../../commons/firestore/firestore-doc.model'

export class FirestoreDataSource<D extends FirestoreDoc> implements TableDataSource<D> {
	constructor(private readonly firestoreListService: FirestoreListService<D>) {}

	connect(collectionViewer: CollectionViewer): Observable<Array<D>> {
		return this.dataSubject.asObservable()
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.disconnected$.next()
		this.dataSubject.complete()
		this.loadingSubject.complete()
	}

	loadData(request: FirestoreRequest = {}): void {
		this.loadingSubject.next(true)
		this.firestoreListService
			.search(request)
			.pipe(
				takeUntil(this.disconnected$),
				catchError((err, caught) => this.onError(err, caught))
			)
			.subscribe((data) => this.onSuccess(data))
	}

	protected onError(error: unknown, caught: Observable<Array<D>>): Observable<Array<D>> {
		console.log(error)
		return of([])
	}

	protected onSuccess(data: Array<D>): void {
		this.dataSubject.next(data)
		this.loadingSubject.next(false)
	}

	private readonly dataSubject = new BehaviorSubject<D[]>([])
	private readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	// tslint:disable-next-line:member-ordering
	readonly loading$: Observable<boolean> = this.loadingSubject.asObservable().pipe(shareReplay(1))
	private readonly disconnected$: Subject<void> = new Subject<void>()
}
