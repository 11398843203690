import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputColorComponent } from './input-color.component'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { FmxErrorsModule } from '../errors/errors.module'
import { ColorPickerModule } from 'ngx-color-picker'
import { FlexModule } from '@angular/flex-layout'

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatInputModule,
		FmxErrorsModule,
		MatIconModule,
		MatButtonModule,
		ColorPickerModule,
		FlexModule,
	],
	declarations: [InputColorComponent],
	exports: [InputColorComponent],
})
export class InputColorModule {}
