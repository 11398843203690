import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms'
import { Observable, of } from 'rxjs'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { ControlValueAccessor } from 'src/app/shared/modules/form/core/control-value-accessor'

import { FileUpload } from 'domain/fileupload'
import { FileUploadService } from 'src/app/shared/services/file-upload.service'

import { Utils } from 'src/app/shared/utils/utils'
import { getDefaultShopSettings, ShopSettings } from '../../../../domain/user/shop-settings.model'
import { catchError } from 'rxjs/operators'

@Component({
	selector: 'app-settings-form',
	templateUrl: './settings-form.component.html',
	styleUrls: ['./settings-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsFormComponent extends ControlValueAccessor<ShopSettings> implements OnInit {
	readonly control: UntypedFormGroup = new UntypedFormGroup({
		uid: new UntypedFormControl(''),
		primary: new UntypedFormControl(''),
		secondary: new UntypedFormControl(''),
		other: new UntypedFormControl(''),
		hidePrice: new UntypedFormControl(false),
		background: new UntypedFormControl(''),
		logoUrl1: new UntypedFormControl(''),
		logoUrl2: new UntypedFormControl(''),
		ownerEmail: new UntypedFormControl(''),
		ownerCompany: new UntypedFormControl(''),
		ownerPhone: new UntypedFormControl(''),
	})

	// upload file
	selectedFile: any
	currentField: any
	currentItem: any
	currentFileUpload: any
	startupload: boolean = false
	message: string = ''
	percentage: number = 0

	imgUrl1_temp: any
	imgUrl2_temp: any

	logoUrl1$!: Observable<string>
	logoUrl2$!: Observable<string>

	constructor(
		@Inject(NgControl) protected readonly ngControl: NgControl,
		@Inject(AngularFireStorage) private readonly storage: AngularFireStorage,
		private readonly uploadService: FileUploadService,
		private readonly cdf: ChangeDetectorRef,
	) {
		super(ngControl)
	}

	ngOnInit(): void {
		super.ngOnInit()

		this.logoUrl1$ = this.storage
			.ref(this.control.value.logoUrl1 || this.defaultShopSettings.logoUrl1)
			.getDownloadURL()
			.pipe(catchError(() => of('')))
		this.logoUrl2$ = this.storage
			.ref(this.control.value.logoUrl2 || this.defaultShopSettings.logoUrl2)
			.getDownloadURL()
			.pipe(catchError(() => of('')))
	}

	// upload file
	selectFile(event: any, field: any): void {
		this.selectedFile = event.target.files
		this.currentField = field
		this.previewFile(event.target.files, field)
		this.uploadFile()
	}

	previewFile(files: any, field: any): void {
		if (files.length === 0) {
			return
		}

		// var mimeType = files[0].type;
		// if (mimeType.match(/image\/*/) == null) {
		//     this.message = 'Only images are supported.';
		//     return;
		// }

		const reader = new FileReader()
		// this.imagePath = files;
		reader.readAsDataURL(files[0])
		reader.onload = () => {
			if (field === 'logoUrl1') {
				this.imgUrl1_temp = reader.result
			}
			if (field === 'logoUrl2') {
				this.imgUrl2_temp = reader.result
			}
			this.cdf.markForCheck()
		}
	}

	uploadFile(): void {
		const self = this
		this.startupload = true

		const file = this.selectedFile.item(0) as File
		delete this.selectedFile

		this.currentItem = { id: Utils.guid(), createdAt: new Date(), name: file.name }

		let ext = 'jpg'
		if (file.name.split('.').length > 0) ext = file.name.split('.')[file.name.split('.').length - 1]

		this.message = 'waiting ...'
		this.currentFileUpload = new FileUpload(file)
		this.uploadService.pushFileToStorage(this.currentFileUpload, this.currentItem, 'files', ext, 'logos').subscribe(
			async (percentage) => {
				this.percentage = Math.round(percentage)

				if (this.percentage === 100) {
					this.currentFileUpload = undefined

					const logoControl = this.control.get(this.currentField) as AbstractControl
					logoControl.setValue('uploads/logos/' + this.currentItem.id + '.' + ext)

					self.message = ''
					this.cdf.markForCheck()
				}
			},
			(error) => {
				console.log(error)
			},
		)
	}

	private readonly defaultShopSettings: ShopSettings = getDefaultShopSettings()
}
