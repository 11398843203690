import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from './modules/auth/auth.service'
import { take } from 'rxjs/operators'
import { NavigationService } from './core/services/navigation.service'

@Component({
	template: `<h1>404</h1>
		<h3>Page not found!</h3>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit {
	url: string = ''

	constructor(
		private router: Router,
		private readonly authService: AuthService,
		private readonly navService: NavigationService
	) {}

	ngOnInit(): void {
		this.url = this.router.url
		this.url = this.url.replace(/\//g, '')
		// this.authService.appUser$.pipe(take(1)).subscribe((user) => {
		// 	if (user) {
		// 		this.navService.goToHome()
		// 	} else {
		// 		this.router.navigate(['auth/login'], { queryParams: { url: this.url } })
		// 	}
		// })
	}
}
