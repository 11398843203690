import { NgModule, Optional, SkipSelf } from '@angular/core'
import { environment } from '../../environments/environment'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog'
import { MatSelectModule } from '@angular/material/select'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { HttpRequestInterceptor } from './http-request-interceptor'
import { FmxErrorsModule } from '../shared/modules/form/errors/errors.module'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateFirebaseLoader } from './firestore-services/translate-firebase-loader'
import { PixelModule } from '@bloomscorp/ngx-pixel'
import { NgcCookieConsentModule } from 'ngx-cookieconsent'
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/compat/storage'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions'
import { AngularFireModule } from '@angular/fire/compat'

// const ANGULAR_FIRE_PROVIDERS = [
// 	// provideFirebaseApp(() => initializeApp(environment.firebase)),
// 	// provideAuth(() => getAuth()),
// 	// provideFirestore(() => getFirestore()),
// 	// provideFunctions(() => getFunctions()),
// 	// provideStorage(() => getStorage()),
// ]

const ANGULAR_FIRE_MODULES = [
	AngularFireModule.initializeApp(environment.firebase),
	AngularFireAuthModule,
	AngularFirestoreModule,
	AngularFireFunctionsModule,
	AngularFireStorageModule,
	AngularFireDatabaseModule,
]

const GLOBAL_MATERIAL_MODULES = [
	MatSnackBarModule,
	MatDialogModule,
	MatSelectModule, // must be imported into a non lazy module until this is fixed: https://github.com/angular/angular/issues/35759
]

export function translateFactory(storage: AngularFireStorage, http: HttpClient): TranslateLoader {
	return new TranslateFirebaseLoader(storage, http, '/i18n')
}

@NgModule({
	imports: [
		...ANGULAR_FIRE_MODULES,
		...GLOBAL_MATERIAL_MODULES,
		FmxErrorsModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateFactory,
				deps: [AngularFireStorage, HttpClient],
			},
		}),
		NgcCookieConsentModule.forRoot({ cookie: { domain: '' }, enabled: false }),
		PixelModule.forRoot({ pixelId: '443279677800571' }),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: '80vw', maxHeight: '80vh' } },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(`Core Module has already been loaded. Import it only in the AppModule.`)
		}
	}
}
