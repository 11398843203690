import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LoadingService {
	start(key: string = 'default'): void {
		this.loadingMap.set(key, true)
		this.loadingSubject.next(true)
	}

	stop(key: string = 'default'): void {
		this.loadingMap.delete(key)
		if (this.loadingMap.size === 0) {
			this.loadingSubject.next(false)
		}
	}

	private readonly loadingMap: Map<string, boolean> = new Map<string, boolean>()
	private readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	// tslint:disable-next-line:member-ordering
	readonly loading$: Observable<boolean> = this.loadingSubject.asObservable().pipe(shareReplay(1))
}
