import { Directive, Input, OnInit } from '@angular/core'
import { ErrorStateMatcher } from '@angular/material/core'
import { MaterialErrorStateMatcher } from './material-error-state-matcher'
import { ControlValueAccessor } from './control-value-accessor'

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class MaterialControlValueAccessor<I = unknown, O = I>
	extends ControlValueAccessor<I, O>
	implements OnInit
{
	@Input() readonly appearance: 'fill' | 'outline' = 'outline'
	@Input() readonly label: string = ''
	@Input() readonly placeholder: string = ''
	@Input() readonly errorLabel: string = ''
	@Input() errorStateMatcher!: ErrorStateMatcher

	ngOnInit(): void {
		super.ngOnInit()
		this.errorStateMatcher = new MaterialErrorStateMatcher(this.externalControl)
	}
}
