import { FirestorePaginatorComponent, PageChangeEvent } from '../firestore-paginator.component'
import { merge, Observable, Subject } from 'rxjs'
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators'
import { FirestoreQueryFnBuilder } from './firestore-query-fn-builder'
import { FirestoreService } from '../../firestore.service'
import { FirestoreRequest } from './firestore-request'
import { FirestoreDoc } from '../../../../../../../commons/firestore/firestore-doc.model'
import { DocumentChangeAction, QueryDocumentSnapshot, QueryFn, QueryGroupFn } from '@angular/fire/compat/firestore'

export interface FirestoreListService<D extends FirestoreDoc> {
	search(request: FirestoreRequest): Observable<Array<D>>
}

interface QueryEvent {
	actions: Array<DocumentChangeAction<unknown>>
	pageIndex: number
}

abstract class AbstractFirestoreListService<D extends FirestoreDoc> implements FirestoreListService<D> {
	protected terminateSub: Subject<void> = new Subject<void>()
	protected readonly paginationService: FirestorePaginationService = new FirestorePaginationService(
		this.paginator as FirestorePaginatorComponent,
	)

	constructor(
		protected readonly firestoreService: FirestoreService<D>,
		protected readonly paginator?: FirestorePaginatorComponent,
	) {}

	search(request: FirestoreRequest): Observable<Array<D>> {
		this.terminateSub.next()
		return this.paginationService.observePagination().pipe(
			switchMap((event) =>
				this.query(this.buildQueryFn(event.builder, request)).pipe(
					map((actions) => ({ actions, pageIndex: event.pageIndex }) as QueryEvent),
				),
			),
			takeUntil(this.terminateSub.asObservable()),
			tap((event) => this.paginationService.onDocumentsChanges(event.actions, event.pageIndex)),
			map((event) => event.actions.map((action) => action.payload.doc.data() as D)),
		)
	}

	protected abstract query(queryFn: QueryFn): Observable<Array<DocumentChangeAction<D>>>

	protected buildQueryFn(paginationBuilder: FirestoreQueryFnBuilder, request: FirestoreRequest): QueryFn {
		return paginationBuilder.withFilters(request.filters).withSort(request.sort).build()
	}
}

export class FirestoreCollectionService<D extends FirestoreDoc> extends AbstractFirestoreListService<D> {
	protected query(queryFn: QueryFn): Observable<Array<DocumentChangeAction<D>>> {
		return this.firestoreService.read(queryFn).snapshotChanges()
	}
}

export class FirestoreCollectionGroupService<D extends FirestoreDoc> extends AbstractFirestoreListService<D> {
	protected query(queryFn: QueryFn): Observable<Array<DocumentChangeAction<D>>> {
		return this.firestoreService.readGroup(queryFn as unknown as QueryGroupFn<D>).snapshotChanges()
	}
}

interface FirestorePaginationEvent {
	builder: FirestoreQueryFnBuilder
	pageIndex: number
}

class FirestorePaginationService {
	constructor(private readonly paginator: FirestorePaginatorComponent) {}

	observePagination(): Observable<FirestorePaginationEvent> {
		this.initPagination()
		return merge(
			this.paginator.pageChange$.pipe(map((event) => this.pageEventToQueryBuilder(event))),
			this.manualPaginationSubject.asObservable().pipe(startWith(this.getFirstPageEvent())),
		)
	}

	onDocumentsChanges(docs: Array<DocumentChangeAction<unknown>>, pageIndex: number): void {
		const length = docs.length

		// documents length changed, possibly removed --> go to first page
		const samePage = pageIndex === this.currentPageIndex
		const docsRemoved = this.pageLengthHistory.has(pageIndex)
			? (this.pageLengthHistory.get(pageIndex) as number) > length
			: false
		if (samePage && docsRemoved) {
			this.pageLengthHistory.clear()
			return this.loadFirstPage()
		}

		// loaded empty last page --> go back one page and mark it as last
		const overflowing = docs.length === 0 && pageIndex > 0
		if (overflowing) {
			this.pageLengthHistory.clear()
			return this.loadPreviousPageBeforeOverflowing()
		}

		this.currentPageIndex = pageIndex

		if (this.paginator.pageSize > length) {
			this.paginator.lastPage()
		}

		if (length > 0) {
			this.first = docs[0].payload.doc
			this.last = docs[length - 1].payload.doc
		}
	}

	private initPagination(): void {
		this.paginator.firstPage()
		this.first = undefined
		this.last = undefined
		this.currentPageIndex = 0
		this.pageLengthHistory.clear()
	}

	private pageEventToQueryBuilder(event: PageChangeEvent): FirestorePaginationEvent {
		const builder = new FirestoreQueryFnBuilder()
		if (event.pageIndex > event.previousPageIndex) {
			builder.withStartAfter(this.last as QueryDocumentSnapshot<unknown>).withLimit(this.paginator.pageSize)
		} else {
			builder.withEndBefore(this.first as QueryDocumentSnapshot<unknown>).withLimitToLast(this.paginator.pageSize)
		}
		return { builder, pageIndex: event.pageIndex }
	}

	private getFirstPageEvent(): FirestorePaginationEvent {
		return {
			builder: new FirestoreQueryFnBuilder().withLimit(this.paginator.pageSize),
			pageIndex: this.currentPageIndex,
		}
	}

	private loadFirstPage(): void {
		this.paginator.firstPage()
		this.currentPageIndex = 0
		this.manualPaginationSubject.next(this.getFirstPageEvent())
	}

	private loadPreviousPageBeforeOverflowing(): void {
		this.paginator.lastPage(true)
		this.currentPageIndex = this.currentPageIndex - 1
		const builder = new FirestoreQueryFnBuilder()
			.withStartAt(this.first as QueryDocumentSnapshot<unknown>)
			.withLimit(this.paginator.pageSize)
		const beforeOverflowEvent: FirestorePaginationEvent = { builder, pageIndex: this.currentPageIndex }
		this.manualPaginationSubject.next(beforeOverflowEvent)
	}

	private first?: QueryDocumentSnapshot<unknown>
	private last?: QueryDocumentSnapshot<unknown>
	private manualPaginationSubject: Subject<FirestorePaginationEvent> = new Subject<FirestorePaginationEvent>()
	private currentPageIndex: number = 0
	/** index to length */
	private pageLengthHistory: Map<number, number> = new Map<number, number>()
}
