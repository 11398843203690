import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { LoadingService } from './services/loading.service'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(@Inject(LoadingService) private readonly loadingService: LoadingService) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.loadingService.start(req.url)
		return next.handle(req).pipe(
			catchError((err) => {
				this.loadingService.stop(req.url)
				return throwError(err)
			}),
			map((evt: HttpEvent<unknown>) => {
				if (evt instanceof HttpResponse) {
					this.loadingService.stop(req.url)
				}
				return evt
			})
		)
	}
}
