import { DestroyAware, untilDestroyed } from "../../shared/utils/destroy-aware.decorator"
import { Injectable } from '@angular/core'
import { DataService } from '../../shared/services/data.service'
import { map, shareReplay, take, takeUntil, tap } from "rxjs/operators"
import { BehaviorSubject, forkJoin, Observable } from "rxjs"
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'
import { Material } from '../../../../domain/material.model'

@DestroyAware()
@Injectable({ providedIn: 'root' })
export class PricingService {
  public priceValid$ = new BehaviorSubject(false)

	public readonly patinatedPrice$ = forkJoin([
		this.dataService.getList('materials').pipe(
			take(1),
			map((mats) => mats.find((mat) => mat.name.toLowerCase() === 'patinat'))
		),
		this.shopSettingsService.shopSettings$,
	]).pipe(
		map(([patinatedMat, settings]) => {
			return Object.assign(new Material(), patinatedMat ?? {}).getPrice(settings.priceCategory, settings.currency)
		}),
    tap(() => this.priceValid$.next(true)),
    shareReplay(1)
	)

	constructor(private readonly dataService: DataService, private readonly shopSettingsService: ShopSettingsService) {}
}
