<ng-container *ngLet="isPublicShop$ | async as isPublicShop">
	<mat-toolbar class="nav" color="primary" role="toolbar" *ngLet="user$ | async as user">
		<div *ngIf="!isPublicShop" (click)="bSideMenu = !bSideMenu" class="ham">
			<svg fill="none" height="35" viewBox="0 0 20 16" width="30" xmlns="http://www.w3.org/2000/svg">
				<path
					clip-rule="evenodd"
					d="M16 0H0.999994C0.447672 0 0 0.44768 0 1.00006C0 1.55212 0.447672 2.00014 0.999994 2.00014H16C16.5523 2.00014 17 1.55212 17 1.00006C17 0.44768 16.5523 0 16 0ZM0.999994 6.99984H19C19.5522 6.99984 20 7.44786 20 7.9999C20 8.5523 19.5522 8.99998 19 8.99998H0.999994C0.447672 8.99998 0 8.5523 0 7.9999C0 7.44786 0.447672 6.99984 0.999994 6.99984ZM0.999994 14H12C12.5523 14 13 14.4477 13 15.0001C13 15.5521 12.5523 16.0002 12 16.0002H0.999994C0.447672 16.0002 0 15.5521 0 15.0001C0 14.4477 0.447672 14 0.999994 14Z"
					fill="#111111"
					fill-rule="evenodd"
				/>
			</svg>
		</div>
		<div class="logo">
			<a (click)="onLogoClick()" style="cursor: pointer">
				<img *ngIf="logoUrl1$ | async as url" [src]="url" class="img-logo" />
			</a>
			<!--		<div class="form">-->
			<!--			<input (input)="setSearchInput($event)" placeholder="{{ 'cauta.produse' | translate }}..." type="search" />-->
			<!--			<mat-icon (click)="search()" class="search">search</mat-icon>-->
			<!--		</div>-->
		</div>

		<div *ngIf="!isPublicShop" class="w-100 nav-options" fxLayout fxLayoutAlign="start center">
			<ng-container *appHasAnyRole="[Role.Admin]">
				<span [routerLinkActive]="['active']" [routerLink]="['admin/users']" class="menu-item">{{
					"obiect.clienti" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['admin/orders']" class="menu-item">{{
					"obiect.comenzi" | translate
				}}</span>
				<!-- <span class="menu-item" [routerLink]="['admin/invoices']" [routerLinkActive]="['active']">Facturi</span> -->
				<span [routerLinkActive]="['active']" [routerLink]="['admin/shoes']" class="menu-item">{{
					"obiect.pantofi" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">{{
					"tab.magazin" | translate
				}}</span>
				<!-- <div fxFlex class="text-center">{{ navbarService.title$ | async }}</div> -->
				<button
					mat-icon-button
					class="nav-action-button"
					(click)="shoppingCart()"
					matTooltip="{{ 'obiect.shoppingCart' | translate }}"
				>
					<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
				</button>
				<app-language-switch></app-language-switch>
				<button
					*ngIf="!hideNotifications"
					mat-icon-button
					class="nav-action-button"
					(click)="notificationsPanel()"
					matTooltip="{{ 'obiect.notificari' | translate }}"
				>
					<mat-icon class="red-badge" [matBadge]="notificationsBadge$ | async" aria-hidden="false"
						>notifications</mat-icon
					>
				</button>
				<button
					(click)="editSettings()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'tab.setari' | translate }}"
				>
					<mat-icon>settings</mat-icon>
				</button>
				<button
					(click)="logout()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'actiune.logout' | translate }}"
				>
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.Editor]">
				<span [routerLinkActive]="['active']" [routerLink]="['editor/shoes']" class="menu-item">{{
					"obiect.pantofi" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">{{
					"tab.magazin" | translate
				}}</span>
				<!-- <div fxFlex class="text-center">{{ navbarService.title$ | async }}</div> -->
				<button
					mat-icon-button
					class="nav-action-button"
					(click)="shoppingCart()"
					matTooltip="{{ 'obiect.shoppingCart' | translate }}"
				>
					<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
				</button>
				<app-language-switch></app-language-switch>
				<button
					*ngIf="!hideNotifications"
					mat-icon-button
					class="nav-action-button"
					(click)="notificationsPanel()"
					matTooltip="{{ 'obiect.notificari' | translate }}"
				>
					<mat-icon class="red-badge" [matBadge]="notificationsBadge$ | async" aria-hidden="false"
						>notifications</mat-icon
					>
				</button>
				<button
					(click)="editSettings()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'tab.setari' | translate }}"
				>
					<mat-icon>settings</mat-icon>
				</button>
				<button
					(click)="logout()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'actiune.logout' | translate }}"
				>
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.BusinessClient]">
				<span [routerLinkActive]="['active']" [routerLink]="['business/orders']" class="menu-item">{{
					"obiect.comenzi" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['business/sellers']" class="menu-item">{{
					"obiect.vanzatori" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['business/shoes']" class="menu-item">{{
					"obiect.pantofi" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">{{
					"tab.magazin" | translate
				}}</span>
				<!-- <div fxFlex class="text-center">{{ navbarService.title$ | async }}</div> -->
				<button
					*ngIf="!hideShoppingCart"
					mat-icon-button
					class="nav-action-button"
					(click)="shoppingCart()"
					matTooltip="{{ 'obiect.shoppingCart' | translate }}"
				>
					<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
				</button>
				<app-language-switch></app-language-switch>
				<button
					*ngIf="!hideNotifications"
					mat-icon-button
					class="nav-action-button"
					(click)="notificationsPanel()"
					matTooltip="{{ 'obiect.notificari' | translate }}"
				>
					<mat-icon class="red-badge" [matBadge]="notificationsBadge$ | async" aria-hidden="false"
						>notifications</mat-icon
					>
				</button>
				<button
					(click)="editSettings()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'tab.setari' | translate }}"
				>
					<mat-icon>settings</mat-icon>
				</button>
				<button
					(click)="logout()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'actiune.logout' | translate }}"
				>
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.SalesAgent]">
				<span [routerLinkActive]="['active']" [routerLink]="['sales/users']" class="menu-item">{{
					"obiect.clienti" | translate
				}}</span>
				<span [routerLinkActive]="['active']" [routerLink]="['sales/orders']" class="menu-item">{{
					"obiect.comenzi" | translate
				}}</span>
				<app-language-switch></app-language-switch>
				<button
					*ngIf="!hideNotifications"
					mat-icon-button
					class="nav-action-button"
					(click)="notificationsPanel()"
					matTooltip="{{ 'obiect.notificari' | translate }}"
				>
					<mat-icon class="red-badge" [matBadge]="notificationsBadge$ | async" aria-hidden="false"
						>notifications</mat-icon
					>
				</button>
				<button
					(click)="editSettings()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'tab.setari' | translate }}"
				>
					<mat-icon>settings</mat-icon>
				</button>
				<button
					(click)="logout()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'actiune.logout' | translate }}"
				>
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.Customer]">
				<span *ngIf="user" class="username">{{ user.name || user.email }}</span>
				<button
					(click)="logout()"
					class="nav-action-button"
					mat-icon-button
					matTooltip="{{ 'actiune.logout' | translate }}"
				>
					<mat-icon>logout</mat-icon>
				</button>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.Seller]">
				<button
					mat-icon-button
					class="nav-action-button"
					(click)="shoppingCart()"
					matTooltip="{{ 'obiect.shoppingCart' | translate }}"
				>
					<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
				</button>
			</ng-container>

			<div class="mobile-search-icon">
				<mat-icon (click)="search()" class="search">search</mat-icon>
			</div>
		</div>

		<div *ngIf="isPublicShop" class="w-100 nav-options" fxLayout fxLayoutAlign="start center">
			<button
				mat-icon-button
				class="nav-action-button"
				(click)="shoppingCart()"
				matTooltip="{{ 'obiect.shoppingCart' | translate }}"
			>
				<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
			</button>
			<app-language-switch></app-language-switch>
		</div>

		<div *ngIf="bSideMenu" class="side-menu">
			<mat-icon (click)="closeMenuMobile()" class="bix">close</mat-icon>
			<ng-container *appHasAnyRole="[Role.Admin]">
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['admin/users']"
					class="menu-item menu-item-first"
				>
					{{ "obiect.clienti" | translate }}
				</p>
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['admin/orders']"
					class="menu-item"
				>
					{{ "obiect.comenzi" | translate }}
				</p>
				<!-- <p class="menu-item" [routerLink]="['admin/invoices']" [routerLinkActive]="['active']"
          (click)="closeMenuMobile()">
          Facturi
        </p> -->
				<p (click)="closeMenuMobile()" [routerLinkActive]="['active']" [routerLink]="['admin/shoes']" class="menu-item">
					{{ "obiect.pantofi" | translate }}
				</p>
				<p (click)="closeMenuMobile()" [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">
					{{ "tab.magazin" | translate }}
				</p>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.Financial]">
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['financial/orders']"
					class="menu-item menu-item-first"
				>
					{{ "obiect.comenzi" | translate }}
				</p>
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['financial/invoices']"
					class="menu-item"
				>
					Facturi
				</p>
				<p (click)="closeMenuMobile()" [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">
					{{ "tab.magazin" | translate }}
				</p>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.Editor]">
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['editor/shoes']"
					class="menu-item menu-item-first"
				>
					{{ "obiect.pantofi" | translate }}
				</p>
				<p (click)="closeMenuMobile()" [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">
					{{ "tab.magazin" | translate }}
				</p>
			</ng-container>

			<ng-container *appHasAnyRole="[Role.BusinessClient]">
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['business/orders']"
					class="menu-item menu-item-first"
				>
					{{ "obiect.comenzi" | translate }}
				</p>
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['business/sellers']"
					class="menu-item"
				>
					{{ "obiect.vanzatori" | translate }}
				</p>
				<p
					(click)="closeMenuMobile()"
					[routerLinkActive]="['active']"
					[routerLink]="['business/shoes']"
					class="menu-item"
				>
					{{ "obiect.pantofi" | translate }}
				</p>
				<p (click)="closeMenuMobile()" [routerLinkActive]="['active']" [routerLink]="['dashboard']" class="menu-item">
					{{ "tab.magazin" | translate }}
				</p>
			</ng-container>

			<!-- <button *ngIf="!user" mat-icon-button (click)="logout()" matTooltip="Autentifică-te">
        <img src="assets/images/login.svg" />
        </button> -->
			<hr *ngIf="user" />
			<div (click)="editSettings()" *ngIf="user" class="logut-container">
				<mat-icon (click)="closeMenuMobile()" class="bipower">settings</mat-icon>
				<p style="margin-left: 10px">Settings</p>
			</div>
			<div (click)="logout()" *ngIf="user" class="logut-container">
				<mat-icon (click)="closeMenuMobile()" class="bipower">power_settings_new</mat-icon>
				<p style="margin-left: 10px">LOGOUT</p>
			</div>
		</div>
		<div class="mobile-only">
			<button
				*ngIf="!hideNotifications && !(isPublicShop$ | async)"
				mat-icon-button
				class="nav-action-button"
				(click)="notificationsPanel()"
				matTooltip="{{ 'obiect.notificari' | translate }}"
			>
				<mat-icon class="red-badge" [matBadge]="notificationsBadge$ | async" aria-hidden="false"
					>notifications</mat-icon
				>
			</button>
			<app-language-switch></app-language-switch>
			<button
				mat-icon-button
				class="nav-action-button"
				style="align-self: flex-end"
				(click)="shoppingCart()"
				matTooltip="{{ 'obiect.shoppingCart' | translate }}"
			>
				<mat-icon class="green-badge" [matBadge]="cartElements" aria-hidden="false">shopping_cart</mat-icon>
			</button>
		</div>
	</mat-toolbar>
</ng-container>
