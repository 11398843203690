import { Order, OrderPixelSummary, ShoeComponentTableItem } from "../../domain/order.model"
import { Shoe, ShoeComponent } from "../../domain/shoe.model"

export class OrderUtils {
  static toOrderPixelSummary(order: Order): OrderPixelSummary {
    const shoeDisplayNames = order.shoes.map((shoe) => shoe.shoe.displayName)
    return {
      amount: order.amount,
      currency: order.currency,
      hasDifferentShoes: order.shoes.length > 1,
      value: order.value_sell,
      shoeDisplayNames
    }
  }

  static computeSummary(shoe: Shoe): Array<ShoeComponentTableItem> {
    const shoeComponentsSummary: Array<ShoeComponentTableItem> = []
    let components = shoe.components.filter((component) => OrderUtils.isValidComponent(component))
    components = OrderUtils.computeComponentExclusions(components)
    components.forEach((component) => {
      let componentName,
        modelOption,
        material,
        patina,
        color = ""
      componentName = component.type.displayName
      modelOption = component.model?.model ? component.model.displayName : ""
      if (modelOption !== "" && component.usematerial) {
        material = component.material.displayName
      } else if (modelOption !== "" && !component.usematerial) {
        material = ""
      } else {
        material = component.material.displayName
      }
      patina = component.material.patinat
      if (component.model?.model && component.usematerial) {
        color = component.color?.displayName || component.model.color?.displayName
      } else if (component.model?.model && !component.usematerial) {
        color = component.model.color?.displayName
      } else {
        color = component.color?.displayName
      }

      /*exceptions*/
      if (material && material.indexOf("mreplacer") !== -1) {
        material = ""
      }
      if (color && color.indexOf("replacer") !== -1) {
        color = ""
      } else if (color === "culoare.none") {
        color = ""
      }

      shoeComponentsSummary.push({
        name: componentName,
        modelOption: modelOption,
        material: material,
        patina: patina,
        color: color
      })
    })
    return shoeComponentsSummary
  }

  private static isValidComponent(component: ShoeComponent): boolean {
    return (
      component.editable && component.model
    )
  }

  /**
   * Takes care of mutually exclusive component options
   * (ex: Varf shouldn't show if Varf Model = None)
   * @private
   */
  private static computeComponentExclusions(components: Array<ShoeComponent>): Array<ShoeComponent> {
    const newArray: Array<ShoeComponent> = []
    const exclusionArray: Array<string> = []
    components.forEach((component) => { // compute exclusions...
      if (component.model?.model === "Fara Benzi Laterale") {
        exclusionArray.push("Banda Stanga", "Banda Mijloc", "Banda Dreapta")
      } else if ((component.model?.model === "Fara" || component.model?.model === "Plain") && (component.model?.hiddenMeshes.indexOf("varf") !== -1 || component.model?.hiddenMeshes.indexOf("toe") !== -1)) {
        exclusionArray.push("Varf")
      } else if (typeof component.model?.model === "string" && component.model?.model.indexOf("Fara Nasturi") !== -1) {
        exclusionArray.push("Nasturi")
      } else if (component.model?.model === "Fara" && component.model?.hiddenMeshes.indexOf("banda") !== -1) {
        exclusionArray.push("Banda/Ciucurei")
      } else if (component.model?.model === "Fara" && component.model?.hiddenMeshes.indexOf("eticheta-lateral") !== -1) {
        exclusionArray.push("Eticheta Laterala")
      }
    })
    components.forEach((component) => { // do excluding...
      if (!exclusionArray.includes(component.type?.name)) {
        newArray.push(component)
      }
    })
    return newArray
  }
}
