<div [formGroup]="control" fxLayout="column">
  <app-input formControlName="customer" label="{{ 'obiect.nume' | translate }}"></app-input>
  <app-input formControlName="phone" label="{{ 'obiect.telefon' | translate }}"></app-input>
	<ng-container *ngIf="publicShop"><app-input formControlName="address" label="{{ 'obiect.adresa' | translate }}"></app-input>
		<app-input formControlName="city" label="{{ 'obiect.localitate' | translate }}"></app-input>
		<app-input formControlName="county" label="{{ 'obiect.judet' | translate }}"></app-input>
		<app-input formControlName="email" label="Email"></app-input>
	</ng-container>
	<p class="table-title" *ngIf="shoess.length">
		{{
			"dialog.cartBeforeOrder"
				| translate
					: {
							numar: shoess.length,
							articole: shoess.length == 1 ? ("obiect.articol" | translate) : ("obiect.articole" | translate)
					  }
		}}
	</p>
	<div *ngIf="shoess.length">
		<app-shoe-orders-form formControlName="shoes"> </app-shoe-orders-form>
	</div>
	<div fxLayout="row-reverse wrap">
		<div class="total-container">
			<div class="total-articles">
				{{ "obiect.numar" | translate }} {{ "obiect.articole" | translate }}
				<div style="float: right">{{ getTotalAmount() }}</div>
			</div>
			<hr />
			<div class="total-price">
				{{ "obiect.total" | translate }}
				<div style="float: right">{{ getTotalPrice() }} {{ currency | async }}</div>
			</div>
		</div>
	</div>
	<div style="padding-top: 15px">
		<mat-checkbox formControlName="agreed" color="primary" class="rounded-checkbox">
			{{ "dialog.acord" | translate }}
		</mat-checkbox>
	</div>
  <div style="padding-top: 15px">
    <mat-checkbox formControlName="termsAccepted" color="primary" class="rounded-checkbox">
      {{ "dialog.termeniPrompt" | translate }} <span style="color: dodgerblue" (click)="openTermsAndConditions($event)">(link)</span>
    </mat-checkbox>
  </div>
	<mat-error *ngIf="agreedControl.invalid && (agreedControl.dirty || agreedControl.touched)">
		<fmx-errors [control]="agreedControl" [resolvers]="[agreementValidationResolver]"></fmx-errors>
	</mat-error>
</div>
