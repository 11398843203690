import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Observable } from 'rxjs'
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'

@Component({
	selector: 'app-auth-layout',
	templateUrl: './auth-layout.component.html',
	styleUrls: ['./auth-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {
	logoUrl1$: Observable<string> = this.shopSettingsService.logoUrl1$
	logoUrl2$: Observable<string> = this.shopSettingsService.logoUrl2$

	constructor(private readonly shopSettingsService: ShopSettingsService) {}
}
