import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms'
import { Order, ShoeOrder } from '../../../../domain/order.model'
import { FormUtils } from '../../shared/modules/form/core/form.utils'
import { EnumUtils } from '../../shared/utils/enum.utils'
import { ControlValueAccessor } from '../../shared/modules/form/core/control-value-accessor'
import { ErrorMessageResolver } from '../../shared/modules/form/errors/error-message-resolver'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { MatDialog, matDialogAnimations } from '@angular/material/dialog'
import { ConfirmationDialog } from '../dialog/confirmation.dialog'
import { CartService } from 'src/app/modules/dashboard/shop/services/cart.service'
import { ShopSettings } from '../../../../domain/user/shop-settings.model'
import { ShopSettingsService } from '../../modules/auth/shop-settings.service'
import { AppUserCurrency } from '../../../../domain/price-categories'
import { filter, map } from 'rxjs/operators'
import { UserTermsDialog } from '../../modules/user-terms/user-terms.dialog'

@Component({
	selector: 'app-cart-order-form',
	templateUrl: './cart-order-form.component.html',
	styleUrls: ['./cart-order-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartOrderComponent extends ControlValueAccessor<Order> implements OnInit {
	// @Input() readonly images: Array<Observable<string>> = []
	@Output() readonly agreed: Observable<boolean>
	readonly amountOptions: Array<number> = Array.from(Array(10).keys())
	readonly control = new UntypedFormGroup({
		agreed: new UntypedFormControl(false, Validators.requiredTrue),
		termsAccepted: new UntypedFormControl(false, Validators.requiredTrue),
		shoes: new UntypedFormControl([], Validators.required),
		value_sell: new UntypedFormControl(this.getTotalPrice()),
	})
	readonly agreementValidationResolver: ErrorMessageResolver = {
		errorKey: 'required',
		getMessage: () => 'Pentru a plasa comanda trebuie sa bifati casuta de confirmare',
	}
	publicShop: boolean = false
	orderSummary: string = ''
	currency: Observable<AppUserCurrency | undefined> = this.shopSettingsService.shopSettings$.pipe(
		map((settings) => settings?.currency),
	)

	constructor(
		@Inject(NgControl) protected readonly ngControl: NgControl,
		@Inject(MatDialog) private readonly dialog: MatDialog,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef,
		@Inject(CartService) private readonly cartService: CartService,
		@Inject(ShopSettingsService) private readonly shopSettingsService: ShopSettingsService,
	) {
		super(ngControl)
		this.agreed = this.agreedChanged
		this.control.statusChanges.subscribe((value) => this.agreedChanged.next(value == 'VALID'))
		//this.agreedControl.valueChanges.subscribe((value) => this.agreedChanged.next(value ?? false))
	}

	@Input() set isPublicShop(value: boolean) {
		this.publicShop = value
	}

	writeValue(value: Order) {
		super.writeValue(value)
	}

	get phoneControl(): AbstractControl {
		return this.control.get('phone') as AbstractControl
	}

	get shoess(): Array<ShoeOrder> {
		return this.control.get('shoes')?.value
	}

	get agreedControl(): AbstractControl {
		return this.control.get('agreed') as AbstractControl
	}

	protected getErrorMessage(err: unknown, model: ShoeOrder): string {
		//this.loading = false
		return `Comanda nu a putut fi plasata`
	}

	ngOnInit(): void {
		this.control.addControl('customer', new UntypedFormControl('', [Validators.required]))
		this.control.addControl(
			'phone',
			new UntypedFormControl('', [Validators.required, Validators.pattern('^[+0-9][0-9]*$')]),
		)
		if (this.publicShop) {
			this.control.addControl('email', new UntypedFormControl('', [Validators.required, Validators.email]))
			this.control.addControl('address', new UntypedFormControl('', [Validators.required]))
			this.control.addControl('city', new UntypedFormControl('', [Validators.required]))
			this.control.addControl('county', new UntypedFormControl('', [Validators.required]))
		}
		super.ngOnInit()
	}

	getTotalPrice(): number {
		if (this.control) {
			return this.shoess
				.map((shoe: ShoeOrder) => {
					return shoe.amount * shoe.value_sell
				})
				.reduce((sum: number, current: number) => sum + current, 0)
		} else {
			return 0
		}
	}

	getTotalAmount() {
		if (this.control) {
			return this.shoess
				.map((shoe: ShoeOrder) => {
					return shoe.amount
				})
				.reduce((sum: number, current: number) => sum + current, 0)
		} else {
			return 0
		}
	}

	openTermsAndConditions(event: Event) {
		event.stopPropagation()
		const dialogRef = this.dialog.open(UserTermsDialog, {
			data: undefined,
			position: { top: '2%' },
			width: '700px',
			disableClose: true,
		})
		dialogRef.afterClosed().subscribe()
	}

	private readonly agreedChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
}
