import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserTermsDialog } from './user-terms.dialog'
import { MatDialogModule } from '@angular/material/dialog'
import { FormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
	declarations: [UserTermsDialog],
	imports: [CommonModule, MatDialogModule, FormsModule, MatCheckboxModule, TranslateModule],
})
export class UserTermsModule {}
