import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FirestorePaginatorComponent } from './firestore/firestore-paginator.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
	imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatIconModule],
	declarations: [FirestorePaginatorComponent],
	exports: [FirestorePaginatorComponent],
})
export class FireModule {}
