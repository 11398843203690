import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
	selector: 'app-gdpr',
	templateUrl: './gdpr.component.html',
	styleUrls: ['./gdpr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GdprComponent {
	constructor() {}
}
