import { NgModule } from '@angular/core'
import { SettingsEditDialog } from './settings-edit.dialog'
import { SettingsFormComponent } from './settings-form.component'
import { SharedModule } from '../shared.module'
import { TableModule } from '../modules/table/table.module'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { FmxSubmitModule } from 'src/app/shared/modules/form/submit/submit.module'
import { InputModule } from '../modules/form/input/input.module'
import { InputColorModule } from '../modules/form/input-color/input-color.module'
import { SelectModule } from '../modules/form/select/select.module'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FireModule } from '../modules/fire/fire.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
	imports: [
		SharedModule,
		TableModule,
		ReactiveFormsModule,
		FormsModule,
		FmxSubmitModule,
		InputModule,
		SelectModule,
		MatDialogModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatTooltipModule,
		MatCheckboxModule,
		FireModule,
		InputColorModule,
		TranslateModule,
	],
	declarations: [SettingsEditDialog, SettingsFormComponent],
	exports: [],
})
export class SettingsModule {}
