import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgcCookieConsentService } from 'ngx-cookieconsent'
import { forkJoin } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class GdprService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly cookieConsentService: NgcCookieConsentService
  ) {}

  init(): void {
    if (this.cookieConsentService.hasConsented()) {
      return
    }

    forkJoin([this.translateService.get('dialog.cookie'), this.translateService.get('dialog.cookieDetails')]).subscribe(
      ([message, link]) => {
        this.cookieConsentService.init({
          cookie: { name: 'mtoshoes_cookie_status', domain: '' },
          palette: {
            popup: {
              background: '#164969',
            },
            button: {
              background: '#ffe000',
              text: '#164969',
            },
          },
          theme: 'classic',
          content: {
            message,
            dismiss: 'OK',
            link,
            href: '/gdpr',
          },
        })
      }
    )
  }

  checkPermissions(): void {
    // forkJoin([
    //   this.translateService.get("dialog.cookie"),
    //   this.translateService.get("dialog.cookieDetails")
    // ]).subscribe(([message, link]) => {
    //   const cc = window as any
    //   cc.cookieconsent.initialise({
    //     palette: {
    //       popup: {
    //         background: "#164969"
    //       },
    //       button: {
    //         background: "#ffe000",
    //         text: "#164969"
    //       }
    //     },
    //     cookie: { name: "mtoshoes_cookie_status" },
    //     onStatusChange(status: any): void {
    //     },
    //     theme: "classic",
    //     content: {
    //       message: message,
    //       dismiss: "OK",
    //       link: link,
    //       href: "/gdpr"
    //     }
    //   })
    // })
  }
}
