<div [formGroup]="control" class="h-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
	<app-input-color formControlName="primary" fxFlex="48" [label]="'culoare.primara' | translate"></app-input-color>

	<app-input-color formControlName="secondary" fxFlex="48" [label]="'culoare.secundara' | translate"></app-input-color>

	<app-input-color formControlName="other" fxFlex="48" [label]="'culoare.tertiara' | translate"></app-input-color>

	<app-input-color formControlName="background" fxFlex="48" [label]="'obiect.fundal' | translate"></app-input-color>

	<mat-checkbox color="primary" class="rounded-checkbox" formControlName="hidePrice" fxFlex="48">{{ 'actiune.ascundePret' | translate}}</mat-checkbox>

  <div fxFlex="100" class="custom-hr"></div>

	<div fxFlex="100" fxLayout="row nowrap" style="margin-top: 10px; margin-bottom: 10px">
		<div fxFlex="50" fxLayout="column nowrap">
      <p>{{ 'upload.logo' | translate}}</p>
			<input (change)="selectFile($event, 'logoUrl1')" type="file" />
			<div *ngIf="message && currentField == 'logoUrl1'" class="msgupload">{{ message }}</div>
    </div>
    <span fxFlex="10"></span>
    <div fxFlex="40">
      <img *ngIf="imgUrl1_temp" [src]="imgUrl1_temp" class="imgupload" />

      <ng-container *ngIf="!imgUrl1_temp">
        <img *ngIf="logoUrl1$ | async as url" [src]="url" class="imgupload" />
      </ng-container>
    </div>
	</div>

  <div fxFlex="100" class="custom-hr"></div>

	<div fxFlex="100" fxLayout="row nowrap" style="margin-top: 10px; margin-bottom: 10px">
    <div fxFlex="50" fxLayout="column nowrap">
      <p>{{ 'upload.coperta' | translate}}</p>
      <input (change)="selectFile($event, 'logoUrl2')" type="file" />
      <div *ngIf="message && currentField == 'logoUrl2'" class="msgupload">{{ message }}</div>
    </div>
    <span fxFlex="10"></span>
    <div fxFlex="40">
			<img *ngIf="imgUrl2_temp" [src]="imgUrl2_temp" class="imgupload" />

			<ng-container *ngIf="!imgUrl2_temp">
				<img *ngIf="logoUrl2$ | async as url" [src]="url" class="imgupload" />
			</ng-container>
    </div>
	</div>

  <div fxFlex="100" class="custom-hr"></div>

  <div fxFlex="100" fxLayout="row wrap" style="margin-top: 10px; margin-bottom: 10px" fxLayoutAlign="space-between center">
    <p fxFlex="100">Footer</p>
    <app-input formControlName="ownerCompany" fxFlex="48" [label]="'obiect.nume' | translate"></app-input>
    <app-input formControlName="ownerEmail" fxFlex="48" label="Email"></app-input>
    <app-input formControlName="ownerPhone" fxFlex="48" [label]="'obiect.telefon' | translate"></app-input>
  </div>
</div>
