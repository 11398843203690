import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AppUser } from 'domain/user/app-user.model'
import { AppUsersFirestoreService } from '../../core/firestore-services/app-users-firestore.service'

@Component({
	template: `
		<h2 matDialogTitle>{{ 'dialog.termeniHeader' | translate }}</h2>

		<mat-dialog-content>
			<pre class="description">{{ 'termeni' | translate }}</pre>
		</mat-dialog-content>

		<br />

		<mat-checkbox *ngIf="user" class="rounded-checkbox" color="primary" [(ngModel)]="agreed">
			{{ 'dialog.termeniPrompt' | translate }}
		</mat-checkbox>

		<div mat-dialog-actions style="justify-content: flex-end">
			<button
				mat-flat-button
				color="primary"
				class="btncontinue"
				[disabled]="!agreed"
				[ngClass]="[agreed ? '' : 'disabled']"
				(click)="close()"
			>
				{{ 'dialog.continua' | translate }}
			</button>
		</div>

		<br />
	`,
	styleUrls: ['./user-terms.dialog.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTermsDialog {
	/*no need to agree if user is not logged in (agree checkbox present in order-form)*/
	agreed: boolean = !this.user

	constructor(
		@Inject(MAT_DIALOG_DATA) readonly user: AppUser,
		protected readonly dialogRef: MatDialogRef<UserTermsDialog>,
		private readonly appUsersFirestoreService: AppUsersFirestoreService,
	) {}

	close(): void {
		if (this.user) {
			const user = { uid: this.user.uid, agreed: new Date().toISOString() } as AppUser
			this.appUsersFirestoreService.update(user).then(() => this.dialogRef.close())
		} else {
			this.dialogRef.close()
		}
	}
}
