import {
	AbstractControl,
	AsyncValidatorFn,
	FormGroupDirective,
	NgForm,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

export class FormUtils {
	/**
	 * Add validators to a control without removing the existing ones
	 */
	static addValidatorsToControl(control: AbstractControl, newValidator: ValidatorFn | ValidatorFn[]): void {
		if (control) {
			const newValidators = Array.isArray(newValidator) ? newValidator : [newValidator]
			const validators = control.validator ? [control.validator, ...newValidators] : newValidators
			control.setValidators(validators)
		}
	}

	/**
	 * Add async validators to a control without removing the existing ones
	 */
	static addAsyncValidatorsToControl(
		control: AbstractControl,
		newValidator: AsyncValidatorFn | AsyncValidatorFn[]
	): void {
		if (control) {
			const newValidators = Array.isArray(newValidator) ? newValidator : [newValidator]
			const validators = control.asyncValidator ? [control.asyncValidator, ...newValidators] : newValidators
			control.setAsyncValidators(validators)
		}
	}

	/**
	 * Get control's errors as an Observable
	 * Main use case: Changes done by async validators into ${@link AbstractControl.errors}
	 * are not spotted when using ${@link ChangeDetectionStrategy.OnPush}
	 */
	static getControlErrors$(control: AbstractControl): Observable<ValidationErrors | null> {
		return control.statusChanges.pipe(
			startWith(''),
			map(() => control.errors)
		)
	}

	static updateControlValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[] | null): void {
		control.setValidators(validators)
		control.updateValueAndValidity()
	}

	static showControlErrors(
		control: AbstractControl,
		form: FormGroupDirective | NgForm | null,
		formGroupValidation: boolean = false
	): boolean {
		const submitted: boolean = !!form?.submitted
		return control && control.invalid
			? this.showControlErrorState(control, submitted)
			: this.showFormGroupErrorState(control, submitted, formGroupValidation)
	}

	static showAsyncControlErrors(control: AbstractControl, formSubmitted = false): boolean {
		return control ? this.showControlErrorState(control, formSubmitted) : false
	}

	private static showFormGroupErrorState(
		control: AbstractControl,
		formSubmitted: boolean,
		formGroupValidation: boolean
	): boolean {
		return this.showControlErrorState(control, formSubmitted) && formGroupValidation
	}

	private static showControlErrorState(control: AbstractControl, formSubmitted: boolean): boolean {
		return control.touched || control.dirty || formSubmitted
	}
}
