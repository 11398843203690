import { animate, state, style, transition, trigger } from '@angular/animations'
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { MatSort, MatSortable, Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable } from 'rxjs'
import { ColActionEvent, ColDef } from './col-def.model'
import { TableDataSource } from './table-data-source'
import { TableExpandableContentTemplateDirective } from './table-expandable-content-template.directive'

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class TableComponent<T extends object> implements OnInit {
	@Input() readonly dataSource!: TableDataSource<T>
	@Input() readonly defaultSort?: MatSortable
	@Input() readonly expandable: boolean = false
	@Input() readonly omitHeaders: boolean = false
	@Output() readonly sort: EventEmitter<Sort> = new EventEmitter<Sort>()
	@Output() readonly columnAction: EventEmitter<ColActionEvent<T, unknown>> = new EventEmitter<
		ColActionEvent<T, unknown>
	>()
	@ViewChild(MatSort, { static: true }) readonly matSort!: MatSort
	@ContentChild(TableExpandableContentTemplateDirective)
	readonly expandableContentTemplateDirective: TableExpandableContentTemplateDirective<T> | null = null
	expanded: T | null = null

	@Input()
	set columns(columns: Array<ColDef<T>>) {
		this.columnsSubject.next(columns ?? [])
		this.columnNamesSubject.next(columns.map((col) => col.property))
	}

	ngOnInit(): void {
		if (this.defaultSort) {
			this.sortColumn(this.defaultSort)
		}
	}

	trackBy(index: number, column: ColDef<T>): string {
		return column.property
	}

	toggleExpansion(row: T): void {
		if (!this.expandable) {
			return
		}
		if (this.expanded === row) {
			this.expanded = null
		} else {
			this.expanded = row
		}
	}

	private sortColumn(sortable: MatSortable): void {
		this.matSort.sort(sortable)
	}

	private readonly columnsSubject: BehaviorSubject<Array<ColDef<T>>> = new BehaviorSubject<Array<ColDef<T>>>([])
	// tslint:disable-next-line:member-ordering
	readonly columns$: Observable<Array<ColDef<T>>> = this.columnsSubject.asObservable()

	private readonly columnNamesSubject: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([])
	// tslint:disable-next-line:member-ordering
	readonly columnNames$: Observable<Array<string>> = this.columnNamesSubject.asObservable()
}
