import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { LoadingService } from '../../core/services/loading.service'
import { Notification } from '../../../../domain/notification.model'
import { AbstractTableComponent } from '../table/abstract-table-component'
import { ColDef } from '../modules/table/col-def.model'
import { DateColumn, TextColumn } from '../modules/table/columns'
import { SnackbarService } from 'src/app/core/services/snackbar.service'
import { FirestoreService } from '../modules/fire/firestore.service'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { MatSortable, Sort } from '@angular/material/sort'
import { FirestoreFilter } from '../modules/fire/firestore/list/firestore-filter'
import { NotificationsFirestoreService } from 'src/app/core/firestore-services/notifications-firestore.service'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { OptionSelectedEvent } from '../modules/form/select/select.component'

@Component({
	selector: 'app-notification-panel-dialog',
	template: `
		<div matDialogTitle style="display: flex;justify-content: space-between;align-items: center;margin-bottom:0px;">
			<h1 style="margin-top:16px">{{ 'obiect.notificari' | translate }}</h1>
			<button mat-button mat-dialog-close (click)="close()">
				<mat-icon class="btn-custom-default">cancel</mat-icon>
			</button>
		</div>
		<mat-dialog-content>
			<div [formGroup]="sortSelectionForm">
				<div fxFlex="63"></div>
				<app-select
					style="border-radius:4px;"
					[items]="sortSelection"
					panelClass="mat-select-notification-dropdown"
					valueProperty="sort"
					formControlName="order"
					fxFlex="33"
					(optionSelectionChange)="onSortSelect($event)"
					><ng-template appSelectOption let-sort>{{ sort.text }}</ng-template></app-select
				>
			</div>
			<app-table
				[dataSource]="dataSource"
				[columns]="columns"
				[defaultSort]="defaultSort"
				[omitHeaders]="omitHeaders"
				(sort)="onSort($event)"
			>
			</app-table>
			<app-firestore-paginator [pageSize]="pageSize" [disabled]="(dataSource.loading$ | async)!">
			</app-firestore-paginator>
		</mat-dialog-content>
		<div mat-dialog-actions></div>
	`,
	styleUrls: ['./notification-panel.dialog.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPanelDialog extends AbstractTableComponent<Notification> {
	readonly defaultSort: MatSortable = { id: 'createdAt', start: 'desc', disableClear: false }
	readonly sortSelection: Array<any> = [
		{ text: 'Cele mai noi', sort: { active: 'createdAt', direction: 'desc' } },
		{ text: 'Cele mai vechi', sort: { active: 'createdAt', direction: 'asc' } },
	]
	readonly omitHeaders: boolean = true
	readonly sortSelectionForm: UntypedFormGroup = new UntypedFormGroup({
		order: new UntypedFormControl(''),
	})
	pageSize: number = 5
	columns: Array<ColDef<Notification>> = [
		DateColumn({
			property: 'createdAt',
			label: 'obiect.creataLa',
			sortable: false,
			data: { format: 'dd.LL.yy HH:mm' },
		}),
		TextColumn({ property: 'data', label: 'obiect.mentiuni' }),
	]
	readonly defaultFilters: Array<FirestoreFilter> = []

	onSortSelect(event: OptionSelectedEvent<Sort>) {
		if (event.isUserInput) {
			super.onSort(event.value as Sort)
		}
	}

	constructor(
		@Inject(NotificationsFirestoreService) firestoreService: FirestoreService<Notification>,
		@Inject(MatDialog) protected readonly dialog: MatDialog,
		@Inject(SnackbarService) protected readonly snackbarService: SnackbarService,
		@Inject(AuthService) protected readonly authService: AuthService,
		@Inject(MatDialogRef) protected readonly dialogRef: MatDialogRef<NotificationPanelDialog>,
		@Inject(LoadingService) readonly loadingService: LoadingService,
	) {
		super(firestoreService, dialog, snackbarService, authService)
		this.sortSelectionForm.get('order')?.setValue('Cele mai noi')
		this.sortSelectionForm.updateValueAndValidity()
	}

	close(): void {}
}
