import { ChangeDetectionStrategy, Component } from '@angular/core'
import { AbstractColumnComponent, TableColumn } from './column'
import { ColDef, PartialColDef } from '../col-def.model'

export interface DateColumnData {
	format?: string
}

@Component({
	template: `{{ value | date: data?.format || undefined }}`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateColumnComponent<T extends object> extends AbstractColumnComponent<T, DateColumnData, DateColumnData> {}

export function DateColumn<T extends object>(
	def: PartialColDef<T, DateColumnComponent<T>>
): ColDef<T, DateColumnComponent<T>> {
	return TableColumn<T, DateColumnComponent<T>>({ ...def, component: DateColumnComponent })
}
