import { Query, QueryDocumentSnapshot, QueryFn } from '@angular/fire/compat/firestore'
import { FirestoreFilter } from './firestore-filter'
import { FirestoreSort } from './firestore-sort'

export class FirestoreQueryFnBuilder {
	// private constructor() {}

	static withSort(value: FirestoreSort): FirestoreQueryFnBuilder {
		return new FirestoreQueryFnBuilder().withSort(value)
	}

	static withFilters(value: Array<FirestoreFilter>): FirestoreQueryFnBuilder {
		return new FirestoreQueryFnBuilder().withFilters(value)
	}

	withSort(value: FirestoreSort | undefined): FirestoreQueryFnBuilder {
		this.sort = value
		return this
	}

	withFilters(value: Array<FirestoreFilter> | undefined): FirestoreQueryFnBuilder {
		this.filters = value ?? []
		return this
	}

	withLimit(value: number): FirestoreQueryFnBuilder {
		this.limitToLast = undefined
		this.limit = value
		return this
	}

	withLimitToLast(value: number): FirestoreQueryFnBuilder {
		this.limit = undefined
		this.limitToLast = value
		return this
	}

	withStartAt(value: QueryDocumentSnapshot<unknown>, withoutEndCursor: boolean = true): FirestoreQueryFnBuilder {
		if (withoutEndCursor) {
			this.withoutEndCursor()
		}
		this.startAfter = undefined
		this.startAt = value
		return this
	}

	withEndAt(value: QueryDocumentSnapshot<unknown>, withoutStartCursor: boolean = true): FirestoreQueryFnBuilder {
		if (withoutStartCursor) {
			this.withoutStartCursor()
		}
		this.endBefore = undefined
		this.endAt = value
		return this
	}

	withStartAfter(value: QueryDocumentSnapshot<unknown>, withoutEndCursor: boolean = true): FirestoreQueryFnBuilder {
		if (withoutEndCursor) {
			this.withoutEndCursor()
		}
		this.startAt = undefined
		this.startAfter = value
		return this
	}

	withEndBefore(value: QueryDocumentSnapshot<unknown>, withoutStartCursor: boolean = true): FirestoreQueryFnBuilder {
		if (withoutStartCursor) {
			this.withoutStartCursor()
		}
		this.endAt = undefined
		this.endBefore = value
		return this
	}

	withoutStartCursor(): FirestoreQueryFnBuilder {
		this.startAt = undefined
		this.startAfter = undefined
		return this
	}

	withoutEndCursor(): FirestoreQueryFnBuilder {
		this.endAt = undefined
		this.endBefore = undefined
		return this
	}

	build(): QueryFn {
		return (query: Query) => {
			query = this.addSorting(query)
			query = this.addFiltering(query)
			query = this.addStartCursor(query)
			query = this.addEndCursor(query)
			return this.addLimit(query)
		}
	}

	private addSorting(query: Query): Query {
		return this.sort ? this.sort.appendTo(query) : query
	}

	private addFiltering(query: Query): Query {
		return this.filters.reduce((q, filter) => filter.appendTo(q), query)
	}

	private addStartCursor(query: Query): Query {
		if (this.startAt) {
			return query.startAt(this.startAt)
		}
		if (this.startAfter) {
			return query.startAfter(this.startAfter)
		}
		return query
	}

	private addEndCursor(query: Query): Query {
		if (this.endAt) {
			return query.endAt(this.endAt)
		}
		if (this.endBefore) {
			return query.endBefore(this.endBefore)
		}
		return query
	}

	private addLimit(query: Query): Query {
		if (this.limit) {
			return query.limit(this.limit)
		}
		if (this.limitToLast) {
			return query.limitToLast(this.limitToLast)
		}
		return query
	}

	private sort: FirestoreSort | undefined
	private filters: Array<FirestoreFilter> = []
	private limit: number | undefined
	private limitToLast: number | undefined
	private startAt: QueryDocumentSnapshot<unknown> | undefined
	private startAfter: QueryDocumentSnapshot<unknown> | undefined
	private endAt: QueryDocumentSnapshot<unknown> | undefined
	private endBefore: QueryDocumentSnapshot<unknown> | undefined
}
