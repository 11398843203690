import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ColDef, PartialColDef } from '../col-def.model'
import { AbstractColumnComponent, TableColumn } from './column'

@Component({
	template: `{{ value }}`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextColumnComponent<T extends object> extends AbstractColumnComponent<T, never> {}

export function TextColumn<T extends object>(
	def: PartialColDef<T, TextColumnComponent<T>>
): ColDef<T, TextColumnComponent<T>> {
	return TableColumn<T, TextColumnComponent<T>>({ ...def, component: TextColumnComponent })
}
