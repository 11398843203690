import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LetDirective } from './let.directive'
import { TemporaryValueDirective } from './temporary-value.directive'
import { SplitByCapitalLetterPipe } from './split-by-capital-letter.pipe'

const DIRECTIVES = [LetDirective]

@NgModule({
	imports: [CommonModule],
	declarations: [...DIRECTIVES, TemporaryValueDirective, SplitByCapitalLetterPipe],
	exports: [CommonModule, ...DIRECTIVES, TemporaryValueDirective, SplitByCapitalLetterPipe],
})
/**
 * Contains shared classes that don't require external modules (except {@link CommonModule}).
 * For shared classes that don't fit this criteria, use ${@link SharedModule}
 */
export class LightSharedModule {}
