import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LightSharedModule } from './modules/light-shared/light-shared.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ColorPreviewDirective } from './color/color-preview.directive'
import { ShoeComponentLabelPipe } from './shoes/shoe-component-label.pipe'
import { ShoeComponentCustomLabelPipe } from './shoes/shoe-component-custom-label.pipe'
import { HasAnyRoleDirective } from './auth/has-any-role.directive'
import { OrderStatusLabelPipe } from './orders/order-status-label.pipe'
import { InvoiceStatusLabelPipe } from './invoices/invoice-status-label.pipe'
import { ShoeCalapodLabelPipe } from './shoes/shoe-calapod-label.pipe'
import { PendingChangesDialog } from './dialog/pending-changes.dialog'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { ConfirmationDialog } from './dialog/confirmation.dialog'
import { InputModule } from './modules/form/input/input.module'
import { ReactiveFormsModule } from '@angular/forms'
import { FmxErrorsModule } from '../shared/modules/form/errors/errors.module'
import { ShoppingCartDialog } from './shopping-cart/shopping-cart.dialog'
import { NotificationPanelDialog } from './notifications/notification-panel.dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { FmxSubmitModule } from '../shared/modules/form/submit/submit.module'
import { MatInputModule } from '@angular/material/input'
import { SelectModule } from './modules/form/select/select.module'
import { TranslateModule } from '@ngx-translate/core'
import { TableModule } from '../shared/modules/table/table.module'
import { FireModule } from '../shared/modules/fire/fire.module'
import { ShoeOrderSummaryComponent } from './orders/shoe-order-summary.component'
import { ShoeLabelComponent } from './shoes/shoe-label/shoe-label.component'
import { NoSpacesPipe } from './utils/trim.pipe'
import { ShoeOrderFormComponent } from './shopping-cart/shoe-order-form.component'
import { ControlMultiplierModule } from './modules/form/control-multiplier/control-multiplier.module'
import { CartOrderComponent } from './shopping-cart/cart-order-form.component'
import { ShoeOrdersFormComponent } from './shopping-cart/shoe-orders-form.component'

const SHARED_MODULES = [LightSharedModule, FlexLayoutModule]

@NgModule({
	imports: [
		SHARED_MODULES,
		MatDialogModule,
		MatButtonModule,
		InputModule,
		ReactiveFormsModule,
		FmxErrorsModule,
		MatCheckboxModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		FmxSubmitModule,
		SelectModule,
		TranslateModule,
		TableModule,
		FireModule,
		ControlMultiplierModule,
		MatDialogModule,
	],
	declarations: [
		ColorPreviewDirective,
		ShoeComponentLabelPipe,
		ShoeComponentCustomLabelPipe,
		HasAnyRoleDirective,
		OrderStatusLabelPipe,
		InvoiceStatusLabelPipe,
		NoSpacesPipe,
		ShoeCalapodLabelPipe,
		PendingChangesDialog,
		ConfirmationDialog,
		ShoppingCartDialog,
		NotificationPanelDialog,
		ShoeOrderSummaryComponent,
		ShoeLabelComponent,
		ShoeOrderFormComponent,
		CartOrderComponent,
		ShoeOrdersFormComponent,
	],
	exports: [
		SHARED_MODULES,
		ColorPreviewDirective,
		ShoeComponentLabelPipe,
		ShoeComponentCustomLabelPipe,
		HasAnyRoleDirective,
		OrderStatusLabelPipe,
		NoSpacesPipe,
		InvoiceStatusLabelPipe,
		ShoeCalapodLabelPipe,
		ShoeOrderSummaryComponent,
		ShoeLabelComponent,
	],
})
/**
 * Contains shared classes that require external modules.
 * For shared classes that only require ${@link CommonModule}, use ${@link LightSharedModule}
 */
export class SharedModule {}
