<mat-form-field [appearance]="appearance" class="w-100">
	<mat-label *ngIf="label">{{ label }}</mat-label>
	<mat-select
		placeholder="{{ placeholder }}"
		[formControl]="control"
		[errorStateMatcher]="errorStateMatcher"
		[multiple]="multiple"
		[compareWith]="compareWith"
		[panelClass]="panelClass"
		(selectionChange)="selectionChange.emit($event?.value)"
	>
		<ng-container *ngIf="!labelOrder">
			<mat-option
				*ngFor="let item of items"
				[value]="getValue(item)"
				[ngClass]="optionClass"
				(onSelectionChange)="onOptionSelected($event)"
			>
				<ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
					<ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
				</ng-container>
				<ng-template #defaultOptionTemplate>{{ item }}</ng-template>
			</mat-option>
		</ng-container>

		<ng-container *ngIf="labelOrder">
			<mat-option
				*ngFor="let item of items | orderBy: labelOrder"
				[value]="getValue(item)"
				[ngClass]="optionClass"
				(onSelectionChange)="onOptionSelected($event)"
			>
				<ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
					<ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
				</ng-container>
				<ng-template #defaultOptionTemplate>{{ item }}</ng-template>
			</mat-option>
		</ng-container>
	</mat-select>

	<mat-error>
		<ng-container *ngIf="errorsTemplate; else defaultErrors">
			<ng-container *ngTemplateOutlet="errorsTemplate; context: { $implicit: externalControl }"></ng-container>
		</ng-container>
		<ng-template #defaultErrors>
			<fmx-errors [control]="externalControl" [controlLabel]="errorLabel || label || placeholder"></fmx-errors>
		</ng-template>
	</mat-error>
</mat-form-field>
