import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { LoadingService } from '../../../core/services/loading.service'

enum LanguageUrl {
	RO = 'https://www.worldometers.info/img/flags/ro-flag.gif',
	EN = 'https://www.worldometers.info/img/flags/us-flag.gif',
	IT = 'https://www.worldometers.info/img/flags/it-flag.gif',
	DE = 'https://www.worldometers.info/img/flags/gm-flag.gif',
}

@Component({
	selector: 'app-language-switch',
	template: `
		<div class="lang-menu">
			<div class="selected-lang" *ngIf="selectedImage$ | async as imgUrl">
				<img [src]="imgUrl" alt="" />
        <mat-icon style="color: #1d1d1d">arrow_drop_down</mat-icon>
			</div>
			<ul style="z-index: 9999">
				<li class="lang-item" *ngFor="let lang of availableLanguages" (click)="switchLanguage(lang)">
					<img [src]="getImage(lang)" alt="" />
					<p fxFlex="50">{{ lang | uppercase }}</p>
				</li>
			</ul>
		</div>
	`,
	styleUrls: ['./language-switch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitchComponent implements OnInit {
	languageImages: { [s: string]: LanguageUrl } = {
		ro: LanguageUrl.RO,
		en: LanguageUrl.EN,
		it: LanguageUrl.IT,
		de: LanguageUrl.DE,
	}
	availableLanguages = Object.keys(this.languageImages)
	selectedImage$: BehaviorSubject<LanguageUrl> = new BehaviorSubject(this.getImage(this.translateService.currentLang))

	constructor(
		@Inject(TranslateService) private readonly translateService: TranslateService,
		@Inject(LoadingService) private readonly loadingService: LoadingService
	) {}

	ngOnInit() {
		this.selectedImage$.next(this.getImage(this.translateService.currentLang))
		this.translateService.onLangChange.subscribe((event: LangChangeEvent) =>
			this.selectedImage$.next(this.getImage(event.lang))
		)
	}

	getImage(language: string) {
		return this.languageImages[language]
	}

	switchLanguage(language: string) {
		this.loadingService.start()
		localStorage.setItem('langPref', language)
		this.translateService.use(language).subscribe(() => this.loadingService.stop())
	}
}
