import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlErrorsTemplateDirective, SelectComponent, SelectOptionTemplateDirective } from './select.component'
import { MatSelectModule } from '@angular/material/select'
import { ReactiveFormsModule } from '@angular/forms'
import { FmxErrorsModule } from '../errors/errors.module'

import { OrderModule } from 'ngx-order-pipe'

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatSelectModule, FmxErrorsModule, OrderModule],
	declarations: [SelectComponent, SelectOptionTemplateDirective, ControlErrorsTemplateDirective],
	exports: [SelectComponent, SelectOptionTemplateDirective, ControlErrorsTemplateDirective],
})
export class SelectModule {}
