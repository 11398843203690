import { TranslateLoader } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { concatMap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { AngularFireStorage } from '@angular/fire/compat/storage'

export class TranslateFirebaseLoader implements TranslateLoader {
	constructor(
		private readonly storage: AngularFireStorage,
		private readonly http: HttpClient,
		private readonly path: string
	) {}

	public getTranslation(lang: string): Observable<any> {
		return this.storage
			.ref(`${this.path}/${lang}.json`)
			.getDownloadURL()
			.pipe(concatMap((url: string) => this.http.get<any>(url)))
	}
}
