import { UserVO } from './user/app-user.model'
import { FilterableFirestoreDoc } from '../commons/firestore/filterable-fire-doc.model'
import { FilterGenerator, FulltextFilterGenerator } from '../commons/firestore/filters-generator'
import { Shoe } from './shoe.model'
import { AppUserCurrency } from './price-categories'

export enum OrderStatus {
	Draft = 'Draft',
	New = 'New',
	// Received = 'Received',
	// InvoiceSent = 'InvoiceSent',
	// InvoicePayed = 'InvoicePayed',
	InProgress = 'InProgress',
	// ReadyToDeliver = 'ReadyToDeliver',
	Sent = 'Sent',
	// InStore = 'InStore',
	// Finished = 'Finished',
}

export enum ShoeCalapod {
	Standard = 'standard',
	Large = 'Large',
}

export interface OrderInvoiceInfo {
	number: string
	series: string
}

export interface StripeCheckoutRequest {
	shopUid: string
	order: Order
}

export interface ShoeComponentTableItem {
  name: string
  modelOption: string
  material: string
  patina: boolean
  color: string
}

export interface ShoeComponentTableRow {
  col1: string
  col2: string
  col3: string
}

export interface ShoeOrder {
	id: string
	shoeSize: number
	shoeCalapod: ShoeCalapod
	description: string
	value: number
	value_sell: number
	amount: number
  /*Pre-translated values used in emails*/
  shoeName?: string
  componentTableRows?: Array<ShoeComponentTableRow>
  imageUrl?: string
	shoe: Shoe
}

export class Order extends FilterableFirestoreDoc<Order> {
	no!: number
	address: string = ''
	email: string = ''
	city: string = ''
	county: string = ''
	phone: string = ''
	lang: string = 'ro'
	businessClient: UserVO = { name: '', uid: '' }
	seller: UserVO = { name: '', uid: '' }
	customer: string = ''
	amount: number = 0
	value: number = 0
	value_sell: number = 0
	currency: AppUserCurrency = AppUserCurrency.RON
	status: OrderStatus = OrderStatus.New
  checkoutDate: string = ''
	lastStatusChangeAt: string = ''
	shoes!: Array<ShoeOrder>
	pay_status: string = ''
	pay_checkoutID: any = ''
	pay_seller: any = {}
	notes: string = ''
	invoiceInfo?: OrderInvoiceInfo

	static withoutMetadata(order?: Order): Order {
		const doc: Order = Object.assign(new Order(), order ?? {})
		return doc.withoutMetadata() as Order
	}

	onCreate(): void {
		super.onCreate()
		this.lastStatusChangeAt = new Date().toISOString()
	}

	equals(other: Order): boolean {
		return (
			super.equals(other) &&
			this.no === other.no &&
			this.customer === other.customer &&
			this.value === other.value &&
			this.status === other.status &&
			this.notes === other.notes
		)
	}

	protected getFilterGenerators(): Array<FilterGenerator<Order>> {
		return [
			new FulltextFilterGenerator((order) => order.businessClient.name),
			new FulltextFilterGenerator((order) => order.customer),
		]
	}
}

export interface OrderPixelSummary {
	shoeDisplayNames: Array<string>
	amount: number
	hasDifferentShoes: boolean
	value: number
	currency: AppUserCurrency
}
