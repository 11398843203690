import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { AbstractFormDialog } from '../../../../../shared/dialog/abstract-form-dialog'
import { Observable } from 'rxjs'
import { MatDialogRef } from '@angular/material/dialog'
import { AuthService } from '../../../../auth/auth.service'
import { FirestoreService } from '../../../../../shared/modules/fire/firestore.service'
import { map, switchMap } from 'rxjs/operators'
import { AppUser } from '../../../../../../../domain/user/app-user.model'
import { AppUsersFirestoreService } from '../../../../../core/firestore-services/app-users-firestore.service'
import { LoadingService } from '../../../../../core/services/loading.service'

@Component({
	template: `
		<form [formGroup]="form" (fmxSubmit)="onSubmit()">
			<h2 matDialogTitle>Confirma</h2>

			<mat-dialog-content>
				<app-order-seller-confirm formControlName="model"></app-order-seller-confirm>
				<mat-error *ngIf="errors$ | async as errors" class="mb-3">{{ errors }}</mat-error>
			</mat-dialog-content>

			<div mat-dialog-actions>
				<button mat-button mat-dialog-close class="btn-custom-default">{{ 'actiune.inchide' | translate }}</button>
				<button
					mat-raised-button
					type="submit"
					color="primary"
					class="btn-custom-primary"
					[disabled]="loadingService.loading$ | async"
				>
					Confirma
				</button>
			</div>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSellerConfirmDialog extends AbstractFormDialog<AppUser, OrderSellerConfirmDialog> {
	seller_found: any

	constructor(
		@Inject(MatDialogRef) protected readonly dialogRef: MatDialogRef<OrderSellerConfirmDialog>,
		@Inject(AppUsersFirestoreService) private readonly sellersFirestoreService: FirestoreService<AppUser>,
		@Inject(AuthService) private readonly authService: AuthService,
		@Inject(LoadingService) readonly loadingService: LoadingService,
	) {
		super(dialogRef, loadingService)
	}

	protected initialFormValue(): any {
		return {}
	}

	protected action$(model: AppUser): Observable<unknown> {
		return this.authService.appUser$.pipe(
			switchMap((appUser) => {
				this.seller_found = { uid: appUser?.uid, name: appUser?.name }
				return this.authService
					.signInWithEmailAndPassword(appUser?.email as string, model.password)
					.pipe(map((user) => appUser))
			}),
		)
	}

	protected getErrorMessage(err: unknown, model: AppUser): any {
		return { status: `Utilizatorul sau parola sunt gresite!`, seller: null }
	}

	protected getSuccessMessage(model: AppUser): any {
		return { status: 'success', seller: this.seller_found }
	}
}
