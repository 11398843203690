import { Inject, Injectable } from '@angular/core'
import { FirestorePathParams, FirestoreService } from '../../shared/modules/fire/firestore.service'
import { Order } from '../../../../domain/order.model'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AuthService } from '../../modules/auth/auth.service'

export interface OrderPathParams extends FirestorePathParams {
	userUid: string
}

@Injectable({ providedIn: 'root' })
export class OrdersFirestoreService extends FirestoreService<Order, OrderPathParams> {
	constructor(
		@Inject(AngularFirestore) protected readonly firestore: AngularFirestore,
		@Inject(AuthService) protected readonly authService: AuthService
	) {
		super(firestore)
	}

	protected get collectionGroupPath(): string | undefined {
		return 'orders'
	}

	protected resolvePath(params?: OrderPathParams): string {
		params = params ?? { userUid: this.authService.userUid as string }
		return `users/${params.userUid}/orders`
	}
}
