import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import uuid from 'uuid-random'

@Injectable({
	providedIn: 'root',
})
export class DataService {
	path: string = ''

	constructor(private angularFireAuth: AngularFireAuth, private afStore: AngularFirestore) {}

	getList(entity: string): Observable<any[]> {
		const ref = this.afStore.collection(entity)
		return ref.valueChanges()
	}

	getSubList(entity: string, id: string, subentity: string): Observable<any[]> {
		const ref = this.afStore.collection(`${entity}/${id}/${subentity}`)
		return ref.valueChanges().pipe(take(1))
	}

	getItemByID(entity: string, id: any): Observable<any> {
		const ref = this.afStore.collection(entity).doc(id)
		return ref.valueChanges().pipe(take(1))
	}

	saveItem(entity: string, item: any) {
		if (!item.uid) item.uid = uuid()

		const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${item.uid}`)
		return itemRef.set(item, { merge: true })
	}

	saveItemById(entity: string, item: any, id: any) {
		if (id) {
			const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${id}`)
			return itemRef.set(item, { merge: true })
		}

		return of(null).toPromise()
	}

	async deleteItem(entity: string, uid: any) {
		// const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${entity}/${uid}`);
		// await itemRef.delete();
		return this.afStore.collection(entity).doc(uid).delete()
	}
}
