import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { shareReplay, takeUntil } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

interface Page {
	start: number
	end: number
	index: number
	last?: boolean
}

export interface PageChangeEvent {
	pageIndex: number
	previousPageIndex: number
}

@Component({
	selector: 'app-firestore-paginator',
	template: `
		<div fxLayout fxLayoutAlign="end center" *ngIf="currentPageExists" style="height: 48px;">
			<span>{{ currentPageSummary }}</span>
			<button mat-icon-button (click)="previousPage()" [disabled]="disabled || isFirstPage">
				<mat-icon>keyboard_double_arrow_left</mat-icon>
			</button>
			<button mat-icon-button (click)="nextPage()" [disabled]="disabled || isLastPage">
				<mat-icon>keyboard_double_arrow_right</mat-icon>
			</button>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirestorePaginatorComponent implements OnInit, OnDestroy {
	@Input() readonly pageSize: number = 10
	@Input() disabled: boolean = false
	@Output() readonly pageChange: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>()

	constructor(
		@Inject(TranslateService) private readonly translateService: TranslateService,
		private readonly cdf: ChangeDetectorRef
	) {}

	get currentPageExists(): boolean {
		return !!this.currentPage
	}

	get currentPageSummary(): string {
		return `${this.pageText} ${this.currentPage.index + 1}`
	}

	get isFirstPage(): boolean {
		return this.currentPage.index === 0
	}

	get isLastPage(): boolean {
		return !!this.currentPage.last
	}

	ngOnInit(): void {
		this.translateService.get('obiect.pagina').subscribe((translatedStr: string) => {
			this.pageText = translatedStr
		})
		this.firstPage()
	}

	ngOnDestroy(): void {
		this.destroyed$.next()
	}

	firstPage(): void {
		this.currentPage = { start: 1, end: this.pageSize, index: 0 }
		this.cdf.markForCheck()
	}

	lastPage(overflow: boolean = false): void {
		if (overflow && !this.isFirstPage) {
			const previousPage: Page = this.getPreviousPage()
			previousPage.last = true
			this.currentPage = previousPage
		} else {
			this.currentPage.last = true
		}
		this.cdf.markForCheck()
	}

	markAsNotLastPage(): void {
		this.currentPage.last = false
		this.cdf.markForCheck()
	}

	previousPage(): void {
		if (this.isFirstPage) {
			return
		}
		const previousPage: Page = this.getPreviousPage()
		const event: PageChangeEvent = this.getEvent(previousPage)
		this.setNewPage(previousPage, event)
	}

	nextPage(): void {
		if (this.isLastPage) {
			return
		}
		const nextPage: Page = this.getNextPage()
		const event: PageChangeEvent = this.getEvent(nextPage)
		this.setNewPage(nextPage, event)
	}

	private setNewPage(page: Page, event: PageChangeEvent): void {
		this.currentPage = page
		this.pageChange.emit(event)
		this.pageChangeSubject.next(event)
	}

	private getNextPage(): Page {
		const start = this.currentPage.start + this.pageSize
		const end = this.currentPage.end + this.pageSize
		const index = this.currentPage.index + 1
		return { start, end, index }
	}

	private getPreviousPage(): Page {
		const start = this.currentPage.start - this.pageSize
		const end = this.currentPage.end - this.pageSize
		const index = this.currentPage.index - 1
		return { start, end, index }
	}

	private getEvent(newPage: Page): PageChangeEvent {
		return { pageIndex: newPage.index, previousPageIndex: this.currentPage.index }
	}

	private readonly destroyed$: Subject<void> = new Subject<void>()
	private currentPage!: Page
	private pageText: string = 'Pagina'
	private readonly pageChangeSubject: Subject<PageChangeEvent> = new Subject<PageChangeEvent>()
	// tslint:disable-next-line:member-ordering
	readonly pageChange$: Observable<PageChangeEvent> = this.pageChangeSubject
		.asObservable()
		.pipe(takeUntil(this.destroyed$), shareReplay(1))
}
