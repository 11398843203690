import { Inject, Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { FirestoreService } from '../../shared/modules/fire/firestore.service'
import { ShopSettings } from '../../../../domain/user/shop-settings.model'
import { Observable, of } from 'rxjs'
import { FirestoreQueryFnBuilder } from '../../shared/modules/fire/firestore/list/firestore-query-fn-builder'
import { FirestoreFilter } from '../../shared/modules/fire/firestore/list/firestore-filter'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class ShopSettingsFirestoreService extends FirestoreService<ShopSettings> {
	constructor(@Inject(AngularFirestore) protected readonly firestore: AngularFirestore) {
		super(firestore)
	}

	getByShopUrl(shopUrl: string): Observable<ShopSettings | null> {
		if (!shopUrl) {
			return of(null)
		}
		const queryFn = FirestoreQueryFnBuilder.withFilters([FirestoreFilter.equals('url', shopUrl)]).build()
		return this.read(queryFn)
			.valueChanges()
			.pipe(map((settings) => settings?.shift() ?? null))
	}

	protected resolvePath(): string {
		return 'shop-settings'
	}
}
