import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	Directive,
	Inject,
	Input,
	TemplateRef,
} from '@angular/core'
import { MaterialControlValueAccessor } from '../core/material-cva'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, NgControl, ValidatorFn } from '@angular/forms'

@Directive({ selector: '[appControlTemplate]' })
export class ControlTemplateDirective {}

@Component({
	selector: 'app-control-multiplier',
	template: `
		<ng-container [formGroup]="form">
			<ng-container formArrayName="controls" *ngFor="let fControl of control.controls; index as i">
				<ng-container
					*ngTemplateOutlet="controlTemplate; context: { $implicit: fControl, index: i, removeAt: removeAt }"
				>
				</ng-container>
			</ng-container>

			<mat-error *ngIf="externalControl && externalControl.invalid && externalControl.touched && externalControl.dirty">
				<fmx-errors [control]="externalControl" [controlLabel]="errorLabel || label || placeholder"></fmx-errors>
			</mat-error>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlMultiplierComponent<T> extends MaterialControlValueAccessor<Array<T>> {
	@Input() readonly validators: ValidatorFn | null = null
	@ContentChild(ControlTemplateDirective, { read: TemplateRef, static: true })
	readonly controlTemplate!: TemplateRef<unknown>

	readonly control: UntypedFormArray = new UntypedFormArray([])
	readonly form: UntypedFormGroup = new UntypedFormGroup({ controls: this.control })

	constructor(
		@Inject(NgControl) protected readonly controlDirective: NgControl,
		@Inject(ChangeDetectorRef) private readonly cdf: ChangeDetectorRef
	) {
		super(controlDirective)
	}

	writeValue(items: Array<T>): void {
		this.control.clear()
		items.forEach((item) => this._add(item))
		this.cdf.markForCheck()
	}

	readonly add = (value?: T, validators?: ValidatorFn) => {
		this._add(value, validators)
		this.cdf.detectChanges()
	}

	readonly removeAt = (index: number) => {
		this.control.removeAt(index)
		this.cdf.detectChanges()
	}

	removeBy(findIndexFn: (item: T) => boolean): void {
		const index = this.control.controls.findIndex((c) => findIndexFn(c.value))
		if (index > -1) {
			this.removeAt(index)
		}
	}

	private _add(value?: T, validators?: ValidatorFn): void {
		this.control.push(new UntypedFormControl(value, validators ?? this.validators))
	}
}
