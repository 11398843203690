import {
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	Type,
	ViewContainerRef,
} from '@angular/core'
import { ColActionEvent, ColDef } from './col-def.model'

@Directive({ selector: '[appTableCellContent]' })
export class TableCellContentDirective<T extends Object> implements OnInit {
	@Input() readonly definition!: ColDef<T>
	@Input() readonly row!: T
	@Output() readonly action: EventEmitter<ColActionEvent<T, unknown>> = new EventEmitter<ColActionEvent<T, unknown>>()

	constructor(
		@Inject(ViewContainerRef) private readonly viewContainerRef: ViewContainerRef,
		@Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		const ref = this.attachToViewContainer(this.definition.component, this.viewContainerRef)
		ref.instance.row = this.row
		ref.instance.def = this.definition
		ref.instance.action$.subscribe((action) => this.action.emit(action))
	}

	private attachToViewContainer<C>(componentType: Type<C>, viewContainer: ViewContainerRef): ComponentRef<C> {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType)
		viewContainer.clear()
		return viewContainer.createComponent(componentFactory)
	}
}
