import { Inject, Injectable } from '@angular/core'
import { FirestoreService } from '../../shared/modules/fire/firestore.service'
import { Notification } from '../../../../domain/notification.model'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AuthService } from '../../modules/auth/auth.service'
import { Observable } from 'rxjs'
import { FirestoreQueryFnBuilder } from '../../shared/modules/fire/firestore/list/firestore-query-fn-builder'
import { FirestoreFilter } from '../../shared/modules/fire/firestore/list/firestore-filter'
import { map, mergeMap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class NotificationsFirestoreService extends FirestoreService<Notification> {
	constructor(
		@Inject(AngularFirestore) protected readonly firestore: AngularFirestore,
		@Inject(AuthService) protected readonly authService: AuthService
	) {
		super(firestore)
	}

	getNewNotificationsCount(): Observable<number> {
		return this.authService.appUserChanges$.pipe(
			map((user) => {
				const lastCheck = user?.lastCheck ?? new Date().toISOString()
				const queryFn = FirestoreQueryFnBuilder.withFilters([
					FirestoreFilter.greaterThan('createdAt', lastCheck),
				]).build()
				return queryFn
			}),
			mergeMap((fireQuery) =>
				this.read(fireQuery)
					.valueChanges()
					.pipe(map((notifications) => notifications.length))
			)
		)
	}

	addNotification(data: string) {
		let notification = new Notification()
		notification.data = `${data} a fost actualizat`
		this.add(notification)
	}

	protected resolvePath(): string {
		return 'notifications'
	}
}
