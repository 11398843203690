import { NgModule } from '@angular/core'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort'
import { LightSharedModule } from '../light-shared/light-shared.module'
import { DateColumnComponent, TextColumnComponent } from './columns'
import { TableCellContentDirective } from './table-cell-content.directive'
import { TableComponent } from './table.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslateModule } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import { TableExpandableContentTemplateDirective } from './table-expandable-content-template.directive'
import { TranslatedTextColumnComponent } from './columns/translated-text-column'

const TABLE_MODULES = [MatTableModule, MatSortModule]

const TABLE_COLUMN_TYPES = [TextColumnComponent, DateColumnComponent, TranslatedTextColumnComponent]

@NgModule({
	imports: [
		TABLE_MODULES,
		LightSharedModule,
		MatProgressSpinnerModule,
		TranslateModule,
		MatButtonModule,
		MatTooltipModule,
		MatIconModule,
	],
	declarations: [
		TableComponent,
		TableCellContentDirective,
		TableExpandableContentTemplateDirective,
		...TABLE_COLUMN_TYPES,
	],
	exports: [...TABLE_MODULES, TableComponent, TableExpandableContentTemplateDirective, ...TABLE_COLUMN_TYPES],
})
export class TableModule {}
