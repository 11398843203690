import { FilterGenerator, FilterValueResolver } from './filter-generator.model'

const arrayFlattener = (previous: Array<string>, current: Array<string>) => [...previous, ...current]

export class FulltextFilterGenerator<M extends object> implements FilterGenerator<M, string> {

  constructor(readonly valueResolver: FilterValueResolver<M, string>) {}

  generate(model: M): Array<string> {
    const value = this.valueResolver(model)
    return FulltextFilterGenerator.generateFilterableKeywords(value)
  }

  private static generateFilterableKeywords(value: string): Array<string> {
    const substrings: Array<string> = Array.from(Array(value.length))
      .map((_, i) => FulltextFilterGenerator.generateSubstrings(value.slice(i).toLowerCase()))
      .reduce(arrayFlattener, [])

    substrings.unshift('')
    return Array.from(new Set<string>(substrings))
  }

  private static generateSubstrings(value: string): Array<string> {
    return Array.from(Array(value.length)).map((_, i: number) => value.substring(0, i + 1))
  }
}
