import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { OrderPixelSummary } from '../../../../domain/order.model'
import { map } from 'rxjs/operators'
import { PixelEventProperties } from 'ngx-pixel/lib/pixel.models'

@Injectable({
  providedIn: 'root',
})
export class OrderUtilsService {
  constructor(private readonly translateService: TranslateService) {}

  translateOrderPixelSummary(summary: OrderPixelSummary): Observable<OrderPixelSummary> {
    return this.translateService.get(summary.shoeDisplayNames).pipe(
      map((nameTranslations: Record<string, string>) => ({
        ...summary,
        shoeDisplayNames: Object.values(nameTranslations),
      }))
    )
  }

  mapToPixelEventProperties(summary: OrderPixelSummary): PixelEventProperties {
    return {
      content_ids: summary.shoeDisplayNames,
      content_type: summary.hasDifferentShoes ? 'product_group' : 'product',
      num_items: summary.amount,
      value: summary.value,
      currency: summary.currency,
    }
  }
}
